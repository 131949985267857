import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdContentPasteSearch,MdEngineering,MdPlaylistAdd,MdLogout,MdOutlineDashboard } from "react-icons/md";
import { IoMdChatboxes } from "react-icons/io";
import { AiOutlineFileProtect } from "react-icons/ai";
import { useMessagesStore } from '../store/UnreadMessages'
import { useUserStore } from '../store/UserAuthenticated'

const Sidebar = () => {
  const { userA,removeUserA } = useUserStore();
  const { unreadMessagesA } = useMessagesStore();
  const navigate = useNavigate();
  //console.log(userA[0])

  const handleLogout = async () => {
    removeUserA();
    localStorage.removeItem('user');
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div
      className="w-[100%] h-[100vh]  rounded-md bg-slate-100 drop-shadow-lg flex flex-col justify-start items-start gap-2"
    >

      <div className="h-[5%] mt-2 ml-1 text-orange-500 font-semibold text-sm">{userA[0].company.name}</div>

      <hr className="ml-1 mr-1 border-gray-300 w-[90%]"/>

      <div className="h-[70%]">
        <Link to="/" className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-50">
          <MdOutlineDashboard size={24}/>
          <p className=" ml-1 text-sm text-center invisible md:visible">Dashboard</p>
        </Link>
        <Link to="/searchcontractor" className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-50">
          <MdContentPasteSearch size={24}/>
          <p className=" ml-1 text-sm text-center invisible md:visible">Search</p>
        </Link>
        <Link to="/contractors" className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-50">
          <AiOutlineFileProtect size={24}/>
          <p className="ml-1 text-sm text-center invisible md:visible ">Stored</p>
        </Link>
        <Link to="/transact-contractor" className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-50">
          <MdEngineering size={24}/>
          <p className="text-sm text-center invisible md:visible ">
            New Transact
          </p>
        </Link>
        <Link to="/transacts-list" className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-50">
          <MdPlaylistAdd size={24}/>
          <p className="ml-1 text-sm text-center invisible md:visible ">Transact list</p>
        </Link>
{/*         <Link to="/chat" className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-50">
          <IoMdChatboxes size={24}/>
            {unreadMessagesA.length > 0 && (
              <span className="absolute top-3 right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                New
              </span>
            )}
          <p className="ml-1 text-sm text-center invisible md:visible ">Chat</p>
        </Link> */}
      </div>

      <hr className="ml-1 mr-1 border-gray-300 w-[90%]"/>

      <div>
        <div className="w-[100%] pl-2">{userA[0].name}</div>
        <button 
          onClick={handleLogout} 
          className="w-[100%] mt-2 p-2 flex justify-start items-end text-slate-600 hover:text-slate-400 hover:bg-orange-100"
        >
            <p className=" mr-1 text-sm text-center invisible md:visible">Logout</p>
            <MdLogout size={24}/>
        </button>
      </div>

    </div>
  );
};

export default Sidebar;
