import Swal from "sweetalert2";

const Alert = (icon, tittle) => {
  //icon = error o success
 return Swal.fire({
    icon: `${icon}`,
    title: `${tittle}`,
    showConfirmButton: false,
    timer: 1000,
  });
};

export default Alert;
