import React, { useState, useEffect } from 'react';
import Layout from "../components/Layout";
import { Tab } from '@headlessui/react'
import SearchContractor from "../components/SearchContractor";
import { UserAuth } from "../contexts/AuthContext";
import ListLicense from "./ListLicense";
import SearchByName from "../components/SearchByName";
import CloseExpireList from "./CloseExpireList";
import IssueDateList from "./IssueDateList";
import { useUserStore } from '../store/UserAuthenticated'
import AreaGraph from "../components/AreaGraph";
import Carousel from '../components/Carousel';
import { CgProfile } from "react-icons/cg";
import { FaSms, FaChartPie } from 'react-icons/fa';
import MyModal from '../components/Modal1';
import { Pie } from 'recharts';
import PieGraph from '../components/PieGraph';

import { useGetTotalLicenses, useGetLicensesByCounty,useGetCompanyLicenses } from "../lib/react-query/queries";
/* import { SMSChart } from "../components/SMSChart"; */

const AnotherDash = () => {
  const authContext = UserAuth();
  const { userA,removeUserA } = useUserStore();

  const { data: companyLicenses  , isLoading3 } = useGetCompanyLicenses(userA[0].company._id);

  console.log(companyLicenses)

  const [counter, setCounter] = useState(0);
  const [counter2, setCounter2] = useState(0);
  const [counter3, setCounter3] = useState(0);
  const targetValue = 277770;
  const targetValue2 = 400;
  const targetValue3 = 1001;
  const duration = 1500; // 2 seconds

  useEffect(() => {
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      setCounter(Math.floor(progress * targetValue));
      setCounter2(Math.floor(progress * targetValue2));
      setCounter3(Math.floor(progress * targetValue3));
    }, 10);

    return () => clearInterval(intervalId);
  }, [targetValue, duration]);

  if (authContext.loading) return <p>Loading...</p>;



  //console.log(userA)

  return (
    <Layout>
      <div className=" flex w-[90vw] ml-[1vw] gap-[1.5vw] mt-4">
        <div className=" w-[17vw] h-[80vh] ">
          <div className='w-full border border-gray-100 h-[10vh] bg-gray-100 shadow-xl rounded-md mb-[2vh] flex justify-center items-center gap-1'>
            <h2 className='text-4xl font-bold text-orange-400'>3.100 </h2><FaSms size={40} className='text-gray-400 ml-1'/><span className='text-xs font-semibold text-gray-400 pt-4'>Sent</span>
          </div>
          <div className='w-full border border-gray-100 h-[68vh] bg-gray-100 shadow-xl rounded-md'>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Jesus Acosta</div>
                <div className='grow-0 bg-orange-300 font-semibold text-white rounded-xl px-1 mr-1'>2500</div>
            </div>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Miguel Navarra</div>
                <div className='grow-0 bg-orange-300 font-semibold text-white rounded-xl px-1 mr-1'>400</div>
            </div>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Julio Ramirez</div>
                <div className='grow-0 bg-orange-300 font-semibold text-white rounded-xl px-1 mr-1'>200</div>
            </div>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Diego Bonilla</div>
                <div className='grow-0 bg-orange-300 font-semibold text-white rounded-xl px-1 mr-1'>200</div>
            </div>
          </div>
        </div>
        <div className=" w-[53vw] border border-gray-200 py-6 text-center shadow-2xl rounded-xl bg-orange-50">
          <h2 className='text-orange-500 font-bold text-xl'>Company contractors management</h2>
            <PieGraph/>
        </div>
        <div className=" w-[17vw] h-[80vh] ">
          <div className='w-full border border-gray-100 h-[15vh] bg-gray-100 shadow-xl rounded-md mb-[2vh]'>
          <div className="text-gray-500 text-center">Contractors Added yesterday:</div>
          <div className="text-4xl font-bold text-gray-500 text-center">{counter2}</div>
          </div>
          <div className='w-full border border-gray-100 h-[63vh] bg-gray-100 shadow-xl rounded-md'>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Jesus Acosta</div>
                <div className='grow-0 '><MyModal/></div>
            </div>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Miguel Navarra</div>
                <div className='grow-0 '><MyModal/></div>
            </div>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Julio Ramirez</div>
                <div className='grow-0 '><MyModal/></div>
            </div>
            <div className='border bg-gray-200 w-[96%] mx-auto mt-2 flex h-[6vh] items-center rounded-md'>
                <div className='grow-0 '><CgProfile size={30}className='text-gray-500 ml-1'/></div>
                <div className='grow text-center text-gray-500 font-semibold'>Diego Bonilla</div>
                <div className='grow-0 '><MyModal/></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AnotherDash;