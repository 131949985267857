import React, { useState, useEffect } from "react";
import Logo from "../assets/img/logo1.png";
import { Link, useNavigate } from "react-router-dom";
import { AiFillLock, AiOutlineMail } from "react-icons/ai";
import { ImEnter } from "react-icons/im";
import axios from "axios"; 
import { useUserStore } from '../store/UserAuthenticated'
import { useForm } from 'react-hook-form'
import { set } from "mongoose";

const Login = () => {
  const addUserAuth = useUserStore((state) => state.addUserA);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async (data) => {
    const { email, password } = data;
    try {
      const response = await axios.post("https://cslb.nexeraadvisors.com/api/auth/login", {
        email: email,
        password: password
      })
      console.log(response)
      if (response.status === 403) {
        setError("Suspended User");
      }
      if (response.status === 401) {
        setError("Incorrect Password");
      }
      if (response.status === 201) {
        const data = await response.data;
        //console.log(data)
        localStorage.setItem("user", data.token);
        addUserAuth(data.user);
        navigate("/");
      }
    } catch (error) {
      if (error.response.request.status === 401) {
        setError("Invalid Password");
      }
      if (error.response.request.status === 404) {
        setError("User does not exist");
      }

      console.error(error.response.request.status);
    }
  }

  useEffect(() => {
    if (error) {
      setDisplayError(true);
      setTimeout(() => {
        setDisplayError(false);
      }, 3500);
    }
  }, [error]);

  return (
    <div className="flex justify-center content-between">
      <div className=" w:1/2 md:w-1/3 bg-slate-100 p-5 mt-40 rounded-lg" >
        <div>
          <img src={Logo} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Log In to your account</h1>
          <div className="my-4">
            <label>Email</label>
            <div className="my-2 w-full relative rounded-2xl shadow-xl" >
            <input
                className="w-full p-2 bg-primary border border-input rounded-2xl"
                type="email"
                name="email"
                {...register("email")}
              />
              <AiOutlineMail className="absolute right-2 top-3 text-gray-400" />
            </div>
          </div>
          <div className="my-4">
            <label>Password</label>
            <div className="my-2 w-full relative rounded-2xl shadow-xl" >
            <input
                className="w-full p-2 bg-primary border border-input rounded-2xl"
                type="password"
                name="password"
                {...register("password")}
              />
              <AiFillLock className="absolute right-2 top-3 text-gray-400" />
            </div>
          </div>
          <button
            className="flex justify-center bg-orange-400 text-white font-bold w-full 
                      my-2 p-3 bg-button text-btnText rounded-2xl shadow-xl hover:bg-orange-600"
          >
            Login <ImEnter className="ml-2 mt-1.5" />
          </button>
        </form>
        { displayError && error && ( <p className="text-small-regular text-error bg-red-500 text-white font-semibold mx-8 rounded-xl text-center  mt-2">{error}</p> )}
        <p className="my-4 flex items-center justify-between">
          <Link to="/forgotpassword" className="text-accent  text-orange-500 hover:text-orange-800">
            Forgot Password?
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
