export async function fetchContractor(licenseNumber, setLicenseData) {
    /* console.log(licenseNumber) */
    const url = `https://cslb.nexeraadvisors.com/api/licenseMaster/${licenseNumber}`;
    await fetch(url)
      .then((response) => response.json())
/*       .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      }) */
      .then((data) => {
        /* console.log(data); */
        setLicenseData(data);
      })
/*       .catch(error => {
        // Handle the error here
        console.error(error)}) */
  }
  