import { create } from 'zustand'
import { persist } from "zustand/middleware";

export const useMessagesStore = create(
    persist(
        (set) =>({
            unreadMessagesA:[],
            addUnreadM: (message) => 
                set((state) => ({
                    unreadMessagesA: [...state.unreadMessagesA, message]
                })),
            removeUnreadM: (userMessage) => 
                set((state) => ({
                    unreadMessagesA: state.unreadMessagesA.filter(unreadM => unreadM.from !== userMessage)
                })),
        }),
        { name:"unreadMess"}
    )
)