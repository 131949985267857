import React, { useState, useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import Layout from "../components/Layout";
import Loading from "../common/Loading";
import { useGetUsers, useGetContractors } from "../lib/react-query/queries";
import axios from "axios";
/* import { UserAuth } from "../contexts/AuthContext"; */
import { IoPeopleCircle } from "react-icons/io5";
import Socket from "../components/Socket";
import { useMessagesStore } from '../store/UnreadMessages'
import { useUserStore } from '../store/UserAuthenticated'

const ChatChannel = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  /* const { user } = UserAuth(); */
  const { userA } = useUserStore();
  const { unreadMessagesA, removeUnreadM } = useMessagesStore();

  const [client, setClient] = useState(null);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [search, setSearch] = useState("");

  const { data: users, isLoading:usersLoading } = useGetUsers();
  /* const filteredUser = users?.find(userf => userf.email === userA[0]?.email); */
  const { data: contractorsq, isLoading: contractorsqLoading } = useGetContractors(userA[0]?.company._id);

  const fetchClient = async (id) => {
    console.log(id)
    try {
      const response = await axios.get(`https://cslb.nexeraadvisors.com/api/license/${id}`);
      setClient(response.data);
    } catch (error) {
      console.error(error);
    }
  };
console.log(client)
  const filteredContractors = searchTerm
  ? contractorsq
    ? contractorsq.filter((contractor) => {
        return (
          contractor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contractor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contractor.businessName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contractor.State.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    : []
  : contractorsq || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //console.log(unreadMessagesA)

  /* const currentItems = filteredContractors.slice(indexOfFirstItem, indexOfLastItem); */
  const currentItems = filteredContractors;
  const filteredContractorsQ = currentItems.filter(contractorQ => 
    unreadMessagesA.some(message => message.from === `${contractorQ.phone}`)
  );
  console.log(currentItems)
  //console.log(userA[0]._id)
  //console.log(filteredContractors)
  
  if ( usersLoading || contractorsqLoading) {
    return <Loading />;
  }

   return (
    <Layout>
      <div className="flex ml-[1vw] mt-6 w-[90vw] gap-1">
        <div className=" h-[80vh] w-1/5 overflow-y-auto border border-gray-300 shadow-xl p-1 rounded-md">
          <input 
            type="text"
            className="border border-gray-300 rounded-md px-1 w-full mb-1" 
            value={search} 
            onChange={e => setSearch(e.target.value)} 
            placeholder="Filter..."
          />
          <div className="flex justify-between items-center gap-1">
          </div>
          <hr className="border border-gray-400 my-1"/>
          {currentItems
          .filter(contractor => contractor.name.toLowerCase().includes(search.toLowerCase()) || contractor.businessName.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => filteredContractorsQ.includes(a) - filteredContractorsQ.includes(b))
          .reverse()
          .map((contractor) => {
            if (contractor.transcribed === false && contractor.userId?._id === userA[0]._id) {
              return (
                  <div key={contractor._id} className="border border-gray-500 rounded-md mb-1 bg-gray-100 shadow-lg flex">
                    <button  
                      className={`flex items-center justify-between p-1 ${contractor._id === selectedContractor ? 'bg-orange-100' : 'bg-gray-100'}`} 
                      onClick={() => {
                        fetchClient(contractor._id);
                        setSelectedContractor(contractor._id);
                        removeUnreadM(`${contractor.phone}`);
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <IoPeopleCircle className="text-xl text-gray-600" />
                        <div>
                          <div className="text-xs">{contractor.businessName}</div>
                          <div className="text-xs text-orange-600">{contractor.name}</div>
                        </div>
                      </div>
                    </button>
                      <div className="text-xs pl-5 pt-3 font-bold">           
                        {filteredContractorsQ.includes(contractor) && (
                          <span className="bg-red-500 p-1 text-white rounded-md">New</span>
                        )}
                      </div>
                  </div>
              )
            }
          })}
        </div>
        {client 
          ? <div className="bg-gray-100 w-4/5"><Socket contractor={client[0]}/></div>
          : <h2 className=" flex text-center font-semibold text-xl ml-4 mt-6">
              <FaChevronLeft className="mt-1"/>
              Please select the client or unread msg.
            </h2>
        }
      </div>
    </Layout>
  );
};

export default ChatChannel;
