export const LicenseClass = {
    A: "General Engineering",
    B: "General Building",
    B2:"Residential Remodeling",
    C: "Specialty",
    C2:"Insulation and Acoustical",
    C4:"Boiler,Hot water heating and steam fitting",
    C5: "Framing and Rough Carpentry",
    C6: "Cabinet, Millwork and Finish Carpentry",
    C7: "Low Voltage Systems",
    C8: "Concrete",
    C9: "Drywall",
    C10: "Electrical",
    C11: "Elevator",
    C12: "Earthwork and Paving",
    C13: "Fencing",
    C15: "Flooring and Floor Covering",
    C16: "Fire Protection",
    C17: "Glazing",
    C20: "Warm-Air-Heating, Ventilating and Air-Conditioning",
    C21: "Building Moving/Demolition",
    C22: "Asbestos Abatement",
    C23: "Ornamental Metal",
    C27: "Landscaping",
    C28: "Lock and Security Equipment",
    C29: "Masonry",
    C31: "Construction Zone Traffic Control",
    C32: "Parking and Highway Improvement",
    C33: "Painting and Decorating",
    C34: "Pipeline",
    C35: "Lathing and Plastering",
    C36: "Plumbing",
    C38: "Refrigeration",
    C39: "Roofing",
    C42: "Sanitation System",
    C43: "Sheet Metal",
    C45: "Sign",
    C46: "Solar",
    C47: "General Manufactured Housing",
    C49: "Tree and palm",
    C50: "Reinforcing Steel",
    C51: "Structural Steel",
    C53: "Swimming Pool",
    C54: "Ceramic and mosaic Tile",
    C55: "Water Conditioning",
    C57: "Well Drilling",
    C60: "Welding",
    C61: "Limited Specialty",
    D1: "Architectural Porcelain",
    D2: "Asbestos Fabrication",
    D3: "Awnings",
    D4: "Central Vacuum System",
    D5: "Communication equipement",
    D6: "Concrete Related Services",
    D7: "Conveyors Cranes",
    D8: "Doors and door services",
    D9: "Drilling, Blasting and oil field work",
    D10: "Elevated Floors",
    D11: "Fencing",
    D12: "Synthetic products",
    D13: "Fire extinguisher systems",
    D14: "Floor Covering",
    D15: "Furnaces",
    D16: "Hardware, locks and safes",
    D17: "Industrial insulation",
    D18: "Prison and jail equipement",
    D19: "Land clearing",
    D20: "Lead burning and fabrication",
    D21: "Machinery and pumps",
    D22: "Marble",
    D23: "Medical gas systems",
    D24: "Metal products",
    D25: "Mirrors and fixes glass",
    D26: "Mobile home installation and repairs",
    D27: "Movable partions",
    D28: "Doors,gates and activating devices",
    D29: "Paperhanging",
    D30: "Pile driving and pressure foundation jacking",
    D31: "Pole installation and maintenance",
    D32: "Power nailing and fastening",
    D33: "Precast concrete stairs",
    D34: "Prefabricated equipement",
    D35: "Pool and spa maintenance",
    D36: "Rigging and rig building",
    D37: "Safes and vaults",
    D38: "Sand and water blasting",
    D39: "Scaffolding",
    D40: "Service station equipement and maintenance",
    D41: "Siding and decking",
    D42: "Non electrical sign installation",
    D43: "Soil grouting",
    D44: "Sprinklers",
    D45: "Staff and stone",
    D46: "Steeple jack work",
    D47: "Tennis court surfacing",
    D48: "Theater and school equipement",
    D49: "Tree service",
    D50: "Suspended Ceilings",
    D51: "Waterproofing and weatherproofing",
    D52: "Window covering",
    D53: "Wood tanks",
    D54: "Rockscaping",
    D55: "Blasting",
    D56: "Trenching only",
    D57: "Propane gas plants",
    D58: "Residential floating docks",
    D59: "Hydroseed spraying",
    D60: "Striping",
    D61: "Gold leaf gilding",
    D62: "Air and water balancing",
    D63: "Construction clean-up",
    D64: "Non specialized",
    D65: "Weatherization and energy conservation"
}