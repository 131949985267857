import React, { useEffect, useState } from "react";
import { UserAuth } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../common/Alert";
import axios from "axios"; //1
import { useForm } from 'react-hook-form'//3

const CreateCompany = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm()//4


  const onSubmit = (data) => {
    const { name, email, phone, address, web } = data;
    axios.post("https://cslb.nexeraadvisors.com/api/companies/create", {
      name: name,
      email: email,
      phone: `+1${phone}`,
      address: address,
      web: web
    })
    .then((response) => {
      Swal.fire({
        icon: "success",
        title: "Company Created",
        showConfirmButton: false,
        timer: 1000,
      }).then(() => {
        navigate("/");
      });
    })
    .catch((err) => {
      Alert("error", "Failed to create company on the server");
    });
  };


  return (
    <div className="ml-[25vw] mt-4">
      <div className="bg-slate-100 p-6 w-[50vw] rounded-xl">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <h1 className="text-center text-xl font-bold">Create a Company</h1>
            <div className="flex gap-3">
              <Link to="/" className="text-accent  text-orange-400 hover:text-orange-700 text-center">
                Back to Main
              </Link>
              <span className="text-accent  text-orange-400">|</span>
              <Link to="/v2/signup" className="text-accent  text-orange-400 hover:text-orange-700 text-center">
                Go create a user
              </Link>
            </div>
          </div>
          <div className="my-4">
            <label>Name</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="text"
              name="name"
              {...register("name")}
            />
          </div>
          <div className="my-4">
            <label>Email</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="email"
              name="email"
              {...register("email")}
            />
          </div>
          <div className="my-4">
            <label>Address</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="text"
              name="address"
              {...register("address")}
            />
          </div>
          <div className="my-4">
            <label>Phone</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="tel"
              name="phone"
              {...register("phone")}
            />
          </div>
          <div className="my-4">
            <label>Website</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="text"
              name="web"
              {...register("web")}
            />
          </div>


          <button
            className="
              bg-orange-400 
              text-white
              font-bold 
              w-full 
              my-2 
              p-2 
              bg-button 
              text-btnText 
              rounded-2xl 
              shadow-xl 
              hover:bg-orange-600"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCompany;