/* import axios from 'axios';

export const getChats = async (companyId) => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/sms/all/${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chats:', error);
    throw error; 
  }
}; */

import axios from 'axios';

export const getChats = async (companyId) => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/sms/all/${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chats:', error);
    throw error; 
  }
};

export const getLastLicenses = async () => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/licenseMaster/recentLicenses/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching licenses:', error);
    throw error; 
  }
};

export const getTotalLicenses = async () => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/statitics/count-licensemaster/all/`)
    return response.data;
  } catch (error) {
    console.error('error fetching total',error);
    throw error;
  }
}

export const getLicensesByCounty = async () => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/statitics/count-licensemaster/byCounty`)
    return response.data;
  }catch(error) {
    console.error('error fetching counties',error);
    throw error;
  }
}

export const getLastUpdateLic = async () => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/statitics/count-licensemaster/lastUpdateDate/`)
    return response.data;
  }catch(error) {
    console.error('error fetching last',error);
    throw error;
  }
}

export const getCompanyLicenses = async (companyId) => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/statitics/count-license/byState/company/${companyId}`);
    return response;
  } catch (error) {
    console.error('Error fetching licenses:', error);
    throw error; 
  }
};

export const getUserLicenses = async (userId) => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/statitics/count-license/byState/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching licenses:', error);
    throw error; 
  }
};


export const getUsers = async () => {
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error; 
  }
};

export const getContractors = async (companyId) => {
  if (typeof companyId !== 'string' || companyId === '') {
    console.log('Invalid companyId');
    return;
  }
  try {
    const response = await axios.get(`https://cslb.nexeraadvisors.com/api/license/company/${companyId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error; 
  }
};


export const postBusinessName = async (businessName) => {
  try {
    const response = await axios.post('https://cslb.nexeraadvisors.com/api/licenseMaster/businessName/', {
      businessName: businessName
    });
    return response.data;
  } catch (error) {
    console.error('Error posting business name:', error);
    throw error;
  }
};