import React from "react";
import Layout from "../components/Layout";
import { Tab } from '@headlessui/react'
import SearchContractor from "../components/SearchContractor";
import { UserAuth } from "../contexts/AuthContext";
import ListLicense from "./ListLicense";
import SearchByName from "../components/SearchByName";
import CloseExpireList from "./CloseExpireList";
import IssueDateList from "./IssueDateList";
import Carousel from "../components/Carousel";

const Home = () => {
  const authContext = UserAuth();

  if (authContext.loading) return <p>Loading...</p>;

  return (
    <Layout>
      <div className="bg-slate-50 p-2 rounded-xl border border-slate-200 shadow-xl">
        <h1 className="font-semibold text-xl text-center text-orange-700 border-b-2">Search by:</h1>
        <Tab.Group>
          <Tab.List>
            <Tab className={({ selected }) => (
                        `w-36 py-2 text-sm leading-5 font-medium rounded-md
                        ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                        )}
            >
              WC Expiration Date
            </Tab>
            <Tab className={({ selected }) => (
                `w-36 py-2 text-sm leading-5 font-medium rounded-md
                ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                )}
            >
              Recent assigned
            </Tab>
            <Tab className={({ selected }) => (
                `w-36 py-2 text-sm leading-5 font-medium rounded-md
                ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                )}
            >
              Business name
            </Tab>
            <Tab className={({ selected }) => (
                `w-36 py-2 text-sm leading-5 font-medium rounded-md
                ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                )}
            >
              License number
            </Tab>
            <Tab className={({ selected }) => (
                `w-36 py-2 text-sm leading-5 font-medium rounded-md
                ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                )}
            >
              Issue Date
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-4">
            <Tab.Panel className="py-1">
              <div>
                <CloseExpireList/>
              </div>
            </Tab.Panel>
            <Tab.Panel className="py-1">
              <div>
                <ListLicense />
              </div>
            </Tab.Panel>
            <Tab.Panel className="py-1">
              <SearchByName />
            </Tab.Panel>
            <Tab.Panel className="py-1">
              <SearchContractor />
            </Tab.Panel>
            <Tab.Panel className="py-1">
              <IssueDateList />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  );
};

export default Home;
