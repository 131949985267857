import Login from "../pages/Login";
import Home from "../pages/Home";
import SavedContractors from "../pages/SavedContractors";
import InfoContractor from "../pages/InfoContractor";
import { Route, Routes } from "react-router-dom";
import Register from "../components/Login/Register";
//import { UserAuth } from "../contexts/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import ForgotPassword from "../components/Login/ForgotPassword";
import ManageContractor from "../pages/ManageContractor";
import Comunications from "../pages/Comunications";
import CreateCompany from "../components/Login/CreateCompany";
import Socket from "../components/Socket";
import SmsChat from "../components/SmsChat";
import TransactContractor from "../pages/TransactContractor";
import TransactList from "../pages/TransactList";
import ChatChannel from "../pages/ChatChannel";
import ActivationAccount from "../pages/ActivationAccount";
import { useUserStore } from '../store/UserAuthenticated'
import ResetPassword from "../pages/ResetPassword";

import AdminDashboard from "../pages/AdminDashboard";
import { useState } from "react";
import Search from "../pages/Search";
import AnotherDash from "../pages/AnotherDash";

const AppRoutes = () => {
  const { userA } = useUserStore();
  //const { user } = UserAuth();
  const [isAdmin, setIsAdmin] = useState(false);
//console.log(userA)
  return (
    <>
      <Routes>
        <Route path="/activation/:token" element={<ActivationAccount />} />
        <Route path="/resetlink/:token" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/socket" element={<Socket />} />
        <Route path="/sms" element={<SmsChat />} />
        <Route path="/dash" element={<AnotherDash />} />
        <Route path="/newinfo/:_license" element={<InfoContractor />} />
        <Route path="/chat" 
          element={
            <ProtectedRoute>
              <ChatChannel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/v2/signup"
          element={
            <ProtectedRoute>
              <Register />
            </ProtectedRoute>
          }
        />
        <Route
          path="/v2/create-company"
          element={
            <ProtectedRoute>
              <CreateCompany />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={
          /* userA.isAdmin ? */ 
          userA.length!==0 && userA[0].isAdmin 
          ? <ProtectedRoute> <AdminDashboard /> </ProtectedRoute> 
          : <ProtectedRoute> <Home /> </ProtectedRoute> } 
        />
{/*         <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/infocontractor"
          element={
            <ProtectedRoute>
              <InfoContractor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/searchcontractor"
          element={
            <ProtectedRoute>
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contractors"
          element={
            <ProtectedRoute>
              <SavedContractors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transact-contractor"
          element={
            <ProtectedRoute>
              <TransactContractor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transacts-list"
          element={
            <ProtectedRoute>
              <TransactList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contractor-detail/:_id"
          element={
            <ProtectedRoute>
              <ManageContractor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/comunications/:_id"
          element={
            <ProtectedRoute>
              <Comunications />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
