import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchContractor } from "../helpers/fetchContractor";
import { setInputValue } from "../features/input/inputSlice";
import ContractorsData from "../components/ContractorsData";
import NotFound from "../components/NotFound";
import { BsSkipForwardFill } from "react-icons/bs";
import { BiSave } from "react-icons/bi";
import Layout from "../components/Layout";
import ManageForm from "../components/ManageForm";
import Swal from "sweetalert2";
import { lastQuery } from "../helpers/lastQuery";
import Loading from "../components/Loading";
import { useUserStore } from '../store/UserAuthenticated'
import axios from "axios";
import { useParams } from "react-router-dom";

const InfoContractor = () => {
  const { userA } = useUserStore();
  const [messageLanguage, setMessageLanguage] = useState("english");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [textSms, setTextSms] = useState("");
  const [licenseData, setLicenseData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [dbUser, setDbUser] = useState([]);
  const [status, setStatus] = useState(0);
  const dispatch = useDispatch();
  const inputValue = useSelector(setInputValue);
  const licenseNumber = Number(inputValue.payload.input.inputValue);
  const handleSkip = () => {
    dispatch(setInputValue(licenseNumber + 1));
    lastQuery(licenseNumber);
  };

  const handleSendSms = (value, text) => {
    setStatus(value);
    setTextSms(text);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const savedContractor = {
      LicenseNo: licenseData.license.LicenseNo,
      name: contactName,
      email: contactEmail,
      phone: `+1${contactPhone}`,
      businessName: licenseData.license.BusinessName,
      entity: licenseData.license.BusinessType,
      issueDate: licenseData.license.IssueDate,
      expireDate: licenseData.license.ExpirationDate,
      licenseStatus: licenseData.license.PrimaryStatus,
      classification: licenseData.license.Classifications,
      bondNumber: licenseData.license.CBNumber,
      bondAmount: licenseData.license.CBAmount,
      bondEffectiveDate: licenseData.license.CBEffectiveDate,
      workersCompensationStatus: licenseData.license.WorkersCompCoverageType,
      CBSuretyCompany: licenseData.license.CBSuretyCompany,
      language: messageLanguage,
      notes: { text: notes },
      State: status,
      companyId: companies._id,
      userId: dbUser[0]._id,
    };
    lastQuery(licenseNumber);
    try {
      console.log(        {
        savedContractor,
        sendSms,
        user: dbUser[0],
        text: textSms,
      })
      const response = await fetch(
        "https://cslb.nexeraadvisors.com/api/license/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            savedContractor,
            sendSms,
            user: dbUser[0],
            text: textSms,
          }),
        }
      );
      //console.log(response)
      if (response.ok) {
        
        Swal.fire({
          icon: "success",
          title: "Contract Saved",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          dispatch(setInputValue(licenseNumber + 1));
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "already been saved",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };
  
  useEffect(() => {
    const fetchUserDb = async () => {
      const response = await fetch(
        `https://cslb.nexeraadvisors.com/api/users/by-email/${userA[0].email}`
      );
      const data = await response.json();
      //console.log(response)
      setDbUser(data);
      const fetchCompany = async () => {
        const response = await fetch(
          `https://cslb.nexeraadvisors.com/api/companies/${data[0].companyId._id}`
        );
        const companyData = await response.json();
        setCompanies(companyData);
        console.log(companies)
      };
      fetchCompany();
    };
    fetchUserDb();
  }, []);
  const { _license } = useParams();

  useEffect(() => {
    setContactName("");
    setContactEmail("");
    setContactPhone("");
    setNotes("");
    setIsLoading(true);
    fetchContractor(licenseNumber, setLicenseData).finally(() =>
    /* fetchContractor(_license, setLicenseData).finally(() => */
      setIsLoading(false)
    );
  }, [licenseNumber]);
  //console.log(_license)
//console.log(userA)
console.log(licenseData)
  if (isLoading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  } else if (licenseData.license) {
    return (
      <Layout>
        <div>
          <div className="flex flex-col md:flex-row gap-6">
            <ContractorsData
              licenseData={licenseData}
              dimensions={"md:w-[50vw] mt-1"}
            />
            <ManageForm
              setContactName={setContactName}
              setContactPhone={setContactPhone}
              setContactEmail={setContactEmail}
              setNotes={setNotes}
              setSendSms={setSendSms}
              contactName={contactName}
              contactEmail={contactEmail}
              contactPhone={contactPhone}
              sendSms={sendSms}
              notes={notes}
              status={status}
              setTextSms={setTextSms}
              setStatus={setStatus}
              user={dbUser[0]}
              handleSendSms={handleSendSms}
              messageLanguage={messageLanguage}
              setMessageLanguage={setMessageLanguage}
              licenseData={licenseData}
              companies={companies}
            />
          </div>
          <div className="flex flex-row justify-end gap-2 mt-1">
            <button
              onClick={handleSkip}
              className="flex bg-orange-400 py-1 px-4 border border-slate-800 
                            rounded-lg text-slate-100 font-bold"
            >
              Skip <BsSkipForwardFill className="mt-1.5 ml-1" />
            </button>
{/*             <button
              onClick={handleSave}
              className="flex bg-slate-800 py-1 px-4 border border-orange-400 
                            rounded-lg text-orange-300 font-bold"
            >
              Save <BiSave className="text-xl mt-1" />
              <small>
                <BsSkipForwardFill className="mt-1.5 ml-1" />
              </small>
            </button> */}
          </div>
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div>
          <NotFound licenseNumber={licenseNumber} />
          <div className="flex flex-row justify-end gap-2 mt-2">
            <button
              onClick={handleSkip}
              className="flex bg-orange-400 py-2 px-4 border border-slate-800 
                                rounded-lg text-slate-100 font-bold"
            >
              Next <BsSkipForwardFill className="mt-1.5 ml-1" />
            </button>
          </div>
        </div>
      </Layout>
    );
  }
};

export default InfoContractor;
