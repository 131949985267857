import React from 'react'
import { MdOutlineSearchOff } from 'react-icons/md'

const NotFound = ({licenseNumber}) => {
  return (
    <div className="md:ml-[25vw] mt-6">
        <div className="h-[65vh] bg-slate-100 p-6 md:w-[50vw] rounded-xlshadow-lg">
            <h2 className='text-2xl text-center font-bold text-orange-500'>{licenseNumber}</h2>
            <h2 className="text-center text-xl mt-[10vh] flex flex-row text-orange-500 font-bold">
                <MdOutlineSearchOff className="text-6xl text-slate-600 ml-auto mr-auto" />
                License Not Found
                <MdOutlineSearchOff className="text-6xl text-slate-600 ml-auto mr-auto" />
            </h2>
            <h2 className="text-center text-xl mt-4">
                The contractor license number you entered is not in our database.
            </h2>
            <h2 className="text-center text-xl mt-4">
                Please check the number and try again.
            </h2>
            <h2 className="text-center text-xl mt-4">
                If you believe this is an error, please contact us.
            </h2>
            <h2 className="text-center text-xl mt-4">
                Thank you.
            </h2>
        </div>
    </div>
  )
}

export default NotFound