import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react'
import Layout from '../components/Layout'
import SmsChannel from '../components/SmsChannel'
import EmailChannel from '../components/EmailChannel'
import Socket from '../components/Socket';
import SmsChat from '../components/SmsChat';
import axios from 'axios';


export default function Comunications() {
    const { _id } = useParams()
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`https://cslb.nexeraadvisors.com/api/license/${_id}`)
          .then(response => response.json())
          .then(data => {
            setData(data);
            setLoading(false);
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
      }, [_id]);

/*       const fetchClient = async (id) => {
        try {
          const response = await axios.get(`https://cslb.nexeraadvisors.com/api/license/${id}`);
          setClient(response.data);
        } catch (error) {
          console.error(error);
        }
      }; */

    if (loading) return "Loading...";
    if (error) return "An error has occurred: " + error.message;

    console.log("comunica",data[0])
  return (
    <Layout>
      <div div className="ml-[5vw] mt-2">
        <div className="bg-slate-100 p-6 w-[80vw] h-[80vh] rounded-xl shadow-xl">
            <div className='flex justify-between'>
              <h1 className="text-3xl font-bold">Comunications</h1>
              <div>
                <p className='text-xs'>{data[0].name}</p>
                <hr />
                <p className='text-xs text-gray-600'>{data[0].businessName}</p>
              </div>
            </div>
            <Tab.Group>
              <Tab.List>
         {/*        <Tab isDisal className={({ selected }) => (
                    `w-36 py-2 text-sm leading-5 font-medium rounded-md
                    ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                    )}
                >
                  SMS
                </Tab> */}
                <Tab className={({ selected }) => (
                    `w-36 py-2 text-sm leading-5 font-medium rounded-md
                    ${selected ? 'text-orange-500 border-b-2 border-orange-500' : 'text-slate-500 hover:bg-orange-200 hover:text-orange-500'}`
                    )}
                >
                  Email
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-4">
                {/* <Tab.Panel className="py-10"><SmsChannel contractor={data[0]}/></Tab.Panel> */}
                {/* <Tab.Panel className="py-1"><Socket/></Tab.Panel> */}
                <Tab.Panel className="py-1"><Socket contractor={data[0]}/></Tab.Panel>
                <Tab.Panel className="py-1"><EmailChannel contractor={data[0]}/></Tab.Panel>
              </Tab.Panels>
            </Tab.Group> 
        </div>
      </div>
    </Layout>

  )
}