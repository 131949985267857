import React, { useState, useRef } from "react";
import Logo from "../assets/img/logo1.png";
import { Link, useNavigate,useParams } from "react-router-dom";
import { AiFillLock, AiOutlineMail } from "react-icons/ai";
import { ImEnter } from "react-icons/im";
import Swal from "sweetalert2";
import axios from "axios"; 
import { useForm } from 'react-hook-form'

const ResetPassword
 = () => {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const { token } = useParams();
  const navigate = useNavigate();
  
  const onSubmit = async (data) => {
    const { password } = data;
    try {
        //console.log( password, token)
        const response = await axios.put("https://cslb.nexeraadvisors.com/api/auth/reset-password", {
            resetLink: token,
            newPassword: password,
          })
          //console.log(response.data);
          Swal.fire({
            title: 'Success!',
            text: 'Your password was changed.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/login');
            }
          });
    } catch (error) {
        console.error(error);
    }
  }

  return (
    <div className="flex justify-center content-between">
      <div className=" w:1/2 md:w-1/3 bg-slate-100 p-5 mt-40 rounded-lg" >
        <div>
          <img src={Logo} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Reset your password</h1>
          <div className="my-4">
            <label> New Password</label>
            <div className="my-2 w-full relative rounded-2xl shadow-xl" >
              <input
                className="w-full p-2 bg-primary border border-input rounded-2xl"
                type="password"
                name="password"
                {...register("password")}
              />
              <AiFillLock className="absolute right-2 top-3 text-gray-400" />
            </div>
          </div>
          <button
            className="flex justify-center bg-orange-400 text-white font-bold w-full my-2 
                      p-3 bg-button text-btnText rounded-2xl shadow-xl hover:bg-orange-600"
          >
            Reset <ImEnter className="ml-2 mt-1.5" />
          </button>
        </form>
        <p className="my-4 flex items-center justify-between">
          <Link to="/login" className="text-accent  text-orange-500 hover:text-orange-800">
            Go back to Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword
;
