import React from 'react'
import './Loading.css'; 

const Loading = () => {
  return (
    <div className="flex h-screen w-full ml-10">
        <div>
            <h1 className='text-2xl text-orange-400 font-bold mb-2'>Fetching Data...</h1>
            <div className="spinner text-center">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    </div>
  )
}

export default Loading