import React, {useEffect} from 'react'
import Navbar from './Navbar'
import Sidebar from './SideBar'
import toast, { Toaster } from 'react-hot-toast';
import io from "socket.io-client";
import Noti from "../assets/audio/notification.mp3";
import { useMessagesStore } from '../store/UnreadMessages'
import { useUserStore } from '../store/UserAuthenticated'
const socket = io("https://cslb.nexeraadvisors.com:5001");

const Layout = (props) => {
  const { userA } = useUserStore();
  const addUnreadMessages = useMessagesStore((state) => state.addUnreadM);
  
  useEffect(() => {
    socket.on("server", (message) => {
      if (message.userId === userA[0]._id) {
        console.log(userA[0])
        console.log("from layout:",message)
        addUnreadMessages(message);
        const audio = new Audio(Noti);
        audio.play();
        toast.custom((t) => (
          <div
            className={`bg-white px-6 py-4 shadow-md border border-orange-300 rounded-full ${
              t.visible ? 'animate-enter' : 'animate-leave'
            }`}
          >
            New Message Income 🔔
          </div>
        ));
      }
    });
    return () => {
      socket.off("server");
    };
  }, []);

  return (
    <>
      <Toaster/>
      <div className='flex w-[100vw]'>
        <div className='w-[10vw]'>
          <Sidebar/>
        </div>
        <div className='flex flex-col w-[89vw]'>
          <Navbar/>
          <div className='principal m-1'>
            {props.children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout