import { createSlice } from '@reduxjs/toolkit'

export const contractorSlice = createSlice({
  name: 'contractor',
  initialState: {
    data: {}
  },
  reducers: {
    setContractor: (state, action) => {
      state.data = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {setContractor } = contractorSlice.actions

export default contractorSlice.reducer