/* import {  Navigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { useUserStore } from '../store/UserAuthenticated'

const ProtectedRoute = ({children}) => {
  const { userA } = useUserStore();
  const { user, loading } = UserAuth();
  
  if (loading) return <p>Loading...</p>

  if (!userA || userA.length===0) return <Navigate to='/login'/>

  return <>{children}</>
};

export default ProtectedRoute; */

import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { useUserStore } from '../store/UserAuthenticated'

const ProtectedRoute = ({ children }) => {
  const { userA } = useUserStore();
  const { user, loading } = UserAuth();
  const location = useLocation();

  if (loading) return <p>Loading...</p>

  return userA && userA.length !== 0 ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default ProtectedRoute;