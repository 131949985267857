/* import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "./queryKeys";

import { getChats } from "../backend/api";

export const useGetChats = (companyId) => {
    return useQuery({
        queryKey:[QUERY_KEYS.GET_CHATS, companyId],
        queryFn: () => getChats(companyId),
        refetchInterval: 15000,
        refetchIntervalInBackground: false,
    })
} */

import { useQuery, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "./queryKeys";
import { getChats, postBusinessName, getLastLicenses, getUsers, getContractors, getTotalLicenses, getLicensesByCounty, getCompanyLicenses, getLastUpdateLic,getUserLicenses } from "../backend/api";

export const useGetChats = (companyId) => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_CHATS, companyId],
    queryFn: () => getChats(companyId),
    refetchInterval: 15000,
    refetchIntervalInBackground: false,
  });
};

export const useGetLastLicenses = () => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_LAST_LICENSES],
    queryFn: () => getLastLicenses(),
  });
};

export const useGetTotalLicenses = () => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_TOTAL_LICENSES],
    queryFn: () => getTotalLicenses(),
  })
}

export const useGetLastUpdateLic = () => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_LAST_UPDATE_LIC],
    queryFn: () => getLastUpdateLic(),
  })
}

export const useGetLicensesByCounty = () => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_LICENCESES_BY_COUNTY],
    queryFn: () => getLicensesByCounty(),
  })
}

export const useGetCompanyLicenses = (companyId) => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_COMPANY_LICENSES, companyId],
    queryFn: () => getCompanyLicenses(companyId),
  });
};

export const useGetUserLicenses = (userId) => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_USER_LICENSES, userId],
    queryFn: () => getUserLicenses(userId),
  });
};

export const useGetUsers = () => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_USERS],
    queryFn: () => getUsers(),
  });
};

export const useGetContractors = (companyId) => {
  return useQuery({
    queryKey:[QUERY_KEYS.GET_CONTRACTORS, companyId],
    queryFn: () => getContractors(companyId),
  });
};


export const usePostBusinessName = (businessName) => {
    return useQuery({
        queryKey:[QUERY_KEYS.POST_BUSSINES, businessName],
        queryFn: () => postBusinessName(businessName),
      });
};

