import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useGetChats } from "../lib/react-query/queries";
import { MdSend } from "react-icons/md";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { set } from "mongoose";

const SmsChat = ({ contractor }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const companyId = contractor.companyId._id;
  const { data: chats } = useGetChats(companyId);
  /* const getChats = useGetChats(companyId) */
  const { register, handleSubmit, reset } = useForm();
  const queryClient = useQueryClient();
  //console.log("contractor",contractor);
  
  const onSubmit = async (data) => {
    const smsToSend = {
      userId: contractor.userId._id,
      fromNumber: contractor.companyId.phone,
      toNumber: [contractor.phone],
      message: data.inputMessage,
      companyName: contractor.companyId.name,
      contact: {
        email: contractor.companyId.email,
        phone: contractor.companyId.phone,
        web: contractor.companyId.web,
      },
    };
    setIsSubmitting(true);
    try {
    console.log(smsToSend)
      const res = await axios.post(
        "https://cslb.nexeraadvisors.com/api/comunications/send-sms",
        smsToSend
      );
      reset();
      queryClient.invalidateQueries(["getChats", companyId]); // refetch the data
    } catch (error) {
      console.error("Error sending message:", error);
    }
    reset();
    setIsSubmitting(false);
  };

  //console.log("chats", chats.filter(chat => (chat.direction === "in" && chat.from === `+1${contractor.phone}` ) || (chat.direction === "out" && chat.to === `+1${contractor.phone}`)))

  return (
    <div className="flex flex-col h-[80vh] px-5">
      <div className="flex-1 h-9/12 overflow-y-auto px-4 py-1">
        {chats &&
          [...chats]
          .filter(chat => (chat.direction === "in" && chat.from === `+1${contractor.phone}` ) || (chat.direction === "out" && chat.to === `+1${contractor.phone}`))
          .map((chat) => {
            const date = new Date(chat.time);
            const formattedDate = `${
              date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()} - ${date.getHours()}:${
              date.getMinutes() < 10 ? "0" : ""
            }${date.getMinutes()}`;
            return (
              <div
                key={chat._id}
                className={`p-3.5 my-4 ${
                  chat.direction === "out"
                    ? "bg-gray-200 border border-gray-400 self-end rounded-tr-2xl rounded-br-2xl rounded-tl-2xl mr-40 shadow-xl"
                    : "bg-orange-200 border border-orange-400 ml-40 rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl shadow-xl"
                }`}
              >
                <p className="flex justify-between">
                  <small className="text-xs text-gray-700">
                    {formattedDate}
                  </small>
                  <span className="font-semibold">
                    {chat.direction === "out" ? "Me" : contractor.name}
                  </span>
                </p>
                <p>
                  {chat.direction === "out"
                    ? chat.text.split("\n")[1]
                    : chat.text}
                </p>
              </div>
            );
          })}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex items-center justify-between px-4 py-2 border-t border-gray-300"
      >
        <input
          type="text"
          {...register("inputMessage", { required: true })}
          placeholder="Write your message..."
          className="flex-1 px-3 py-2 mr-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-white bg-orange-500 rounded-lg hover:bg-orange-600 focus:outline-none focus:bg-orange-600"
        >
          Send <MdSend className="inline-block ml-2" />
        </button>
      </form>
    </div>
  );
};

export default SmsChat;
