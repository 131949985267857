import React, { useState, useRef } from "react";
import Logo from "../../assets/img/logo1.png";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const emailRef = useRef();
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const { email } = data;
    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Please enter an email",
        showConfirmButton: true,
      }).then(() => {
        setTimeout(() => {
          emailRef.current.focus();
        }, 1000);
      });
      return;
    }
    try {
      const response = await axios.put(
        "https://cslb.nexeraadvisors.com/api/auth/forgot-password",
        {
          email: email,
        }
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: `We sent you an email to ${email} to reset your password`,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate("/");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: `${error.message}`,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  return (
    <div className="flex justify-center content-between">
      <div className=" w:1/2 md:w-1/3 bg-slate-100 p-5 mt-40 rounded-lg">
        <div>
          <img src={Logo} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Reset your password</h1>
          <div className="my-4">
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
              <label>Email</label>
              <input
                className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
                type="email"
                name="email"
                {...register("email")}
              />
              <AiOutlineMail className="absolute right-2 top-3 text-gray-400" />
            </div>
          </div>
          <button
            className="flex justify-center bg-orange-400 text-white font-bold w-full my-2 
                      p-3 bg-button text-btnText rounded-2xl shadow-xl hover:bg-orange-600"
          >
            Reset Password
          </button>
        </form>
        <p className="my-4 flex items-center justify-between">
          <Link
            to="/login"
            className="text-accent  text-orange-400 hover:text-orange-700"
          >
            Back to Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
