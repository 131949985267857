import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setInputValue } from "../features/input/inputSlice";
import { useNavigate } from "react-router";
import { GiMiningHelmet } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { lastQuerySearched } from "../helpers/lastSearch";

const SearchContractor = () => {
  const navigate = useNavigate();
  const [lastQueryS, setLastQueryS] = useState("");
  const [inputValue, setInputValueLocal] = useState("");
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setInputValueLocal(event.target.value);
  };

  const handleButtonClick = () => {
    dispatch(setInputValue(inputValue));
    navigate("/infocontractor");
  };

  useEffect(() => {
    lastQuerySearched(setLastQueryS);
  }, []);

  return (
    <div className="px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64 2xl:px-80">
      <div className=" md:w-full rounded-xl">
        <form className="flex flex-col">
          <h2 className="text-center text-xl">
            Enter the contractor <b><i>license number</i></b> to check the status of their
            license.{" "}
            <span className="text-sm font-bold">
              Last query on DB:
              <span className="text-orange-500"> {lastQueryS}</span>
            </span>
          </h2>
          <div className="my-2 w-full relative rounded-2xl shadow-xl">
            <input
              type="text"
              onChange={handleInputChange}
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
            />
            <GiMiningHelmet className="absolute right-2 top-3 text-xl text-gray-500" />
          </div>
        </form>
        <button
          onClick={handleButtonClick}
          className="flex justify-center bg-orange-400 text-white font-bold 
                      w-full my-2 p-2 bg-button text-btnText rounded-2xl 
                      shadow-xl hover:bg-orange-600"
        >
          search <AiOutlineSearch className="ml-2 mt-1.5 text-white text-lg" />
        </button>
      </div>
    </div>
  );
};

export default SearchContractor;
