export const QUERY_KEYS = {
    GET_CHATS : "getChats",
    POST_BUSSINES : "postBusinessName",
    GET_LAST_LICENSES:"getLastLicenses",
    GET_TOTAL_LICENSES:"getTotalLicenses",
    GET_LICENCESES_BY_COUNTY:"getLicensesByCounty",
    GET_USERS:"getUsers",
    GET_CONTRACTORS:"getContractors",
    GET_COMPANY_LICENSES:"getCompanyLicenses",
    GET_USER_LICENSES:"getUserLicenses",
    GET_LAST_UPDATE_LIC:"getLastUpdateLic"
  };