import React,{useEffect, useState} from "react";
import { VscSearchStop } from "react-icons/vsc";
import parsePhoneNumber from 'libphonenumber-js';
import { LicenseClass } from "../lib/data/LicenseClassification";

const ContractorsData = ({ licenseData, dimensions }) => {

  const [phoneNumber, setPhoneNumber] = useState("");
  const  [license, setLicense] = useState({
    LicenseNo:"",
    BusinessName:"",
    MailingAddress:"",
    City:"",
    ZIPCode:"",
    BusinessPhone:"",
    BusinessType:"",
    Classifications:"",
    IssueDate:"",
    ExpirationDate:"",
    PrimaryStatus:"",
    WorkersCompCoverageType:"",
    WCExpirationDate:"",
    WCInsuranceCompany:""
  })
  const [personalList, setPersonalList] = useState({
    Name:"",
    "EMP-Titl-CDE":"",
  })

  useEffect(() => {
  if(licenseData.message ){
    console.log(licenseData.message)   
  }else{
    setLicense({
      LicenseNo:licenseData.license.LicenseNo,
      BusinessName:licenseData.license.BusinessName,
      MailingAddress:licenseData.license.MailingAddress,
      City:licenseData.license.City,
      ZIPCode:licenseData.license.ZIPCode,
      BusinessPhone:licenseData.license.BusinessPhone,
      BusinessType:licenseData.license.BusinessType,
      Classifications:licenseData.license.Classifications,
      IssueDate:licenseData.license.IssueDate,
      ExpirationDate:licenseData.license.ExpirationDate,
      PrimaryStatus:licenseData.license.PrimaryStatus,
      WorkersCompCoverageType:licenseData.license.WorkersCompCoverageType,
      WCExpirationDate:licenseData.license.WCExpirationDate,
      WCInsuranceCompany:licenseData.license.WCInsuranceCompany,
      CBSuretyCompany:licenseData.license.CBSuretyCompany
    });

    if (licenseData && licenseData.personalList) {
      setPersonalList({
        Name: licenseData.personalList.Name,
        title: licenseData.personalList["EMP-Titl-CDE"],
      });
    }
    if(licenseData.license.BusinessPhone){
      const phoneNumbern = parsePhoneNumber(licenseData.license.BusinessPhone, 'US');
      setPhoneNumber(phoneNumbern.getURI())
    }
  }
  }, [licenseData])

  function MyClassif({ license }) {
    //console.log(license)
    
    if (license.charAt(1)==="-") {
      return <span>{LicenseClass[license.replace('-', '')] || 'Unknown classification'}</span>;
    }

    return <span>{LicenseClass[license] || 'Unknown classification'}</span>;
  }
  //console.log(licenseData)
  return (
    <>
      {licenseData.message ? (
        <div className={`${dimensions} p-4 md:pl-8 border rounded-2xl shadow-2xl md:ml-[10vw] md:h-[75vh]`}>
          <h1 className="flex flex-row justify-center text-center font-bold text-3xl mt-20 text-red-500">
            <VscSearchStop/> - License Not Found - <VscSearchStop/>
          </h1>
        </div>
      ) : (
        <div className={`${dimensions} p-4 md:pl-8 border rounded-2xl shadow-2xl md:ml-[3vw] md:h-[85vh]`}>
          <div className="bg-slate-600 text-orange-500 py-1 px-4 rounded-t-xl shadow-lg">
            <h1 className="text-center text-xl mt-1 mb-2 font-bold">
              Bussiness Information - #{license.LicenseNo}
            </h1>
            <table className="w-full">
              <tbody>
                <tr>
                  <td>Company Name:</td>
                  <td className="text-center">{license.BusinessName}</td>
                </tr>
                <tr>
                  <td>Address - City/ZIP:</td>
                  <td className="text-center">{license.MailingAddress} - {license.City} / {license.ZIPCode}</td>
                </tr>
{/*                 <tr>
                  <td>City/ZIP:</td>
                  <td className="text-center">{license.City} / {license.ZIPCode}</td>
                </tr> */}
                <tr>
                  <td>Phone:</td>
                  <td className="text-center">
                    <a href={phoneNumber} 
                      className="bg-yellow-100 no-underline p-0.5 opacity-80 text-blue-600 font-medium">
                        {`${license.BusinessPhone?.slice(0,9)}-${license.BusinessPhone?.slice(10)}`}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Entity:</td>
                  <td className="text-center">
                    {license.BusinessType}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Issue Date:  <span className="ps-2">{new Date(license.IssueDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span></p>
                  </td>
                  <td className="text-center">
                    <p>Expire Date:  <span className="ps-2">{new Date(license.ExpirationDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span></p>
                  </td>
                </tr>
                <tr>
                  <td>Surety Company:</td>
                  <td className="text-center text-sm text-gray-100">
                    {license.CBSuretyCompany}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="bg-orange-100 py-1 px-4 rounded-b-xl mt-1 shadow-lg  shadow-orange-200">
            <h2 className="text-center text-xl font-bold text-slate-500">
              Details
            </h2>
            <table className="w-full text-slate-500">
              <tbody>
                <tr>
                  <td>Classification:</td>
                  <td className="text-center text-slate-500">{license.Classifications} / <MyClassif license={license.Classifications} /></td>
                </tr>
                <tr>
                  <td>License Status:</td>
                  <td className="text-center">
                    {license.PrimaryStatus==="CLEAR" 
                      ? <span className="text-green-500 font-bold">License is currently active and {license.PrimaryStatus}</span> 
                      : <span className="text-red-500 font-bold">{license.PrimaryStatus}</span> }
                  </td>
                </tr>
                <tr>
                  <td>Workers Compensation:</td>
                  <td className="text-center">{license.WorkersCompCoverageType}</td>
                </tr>
                <tr>
                  <td>WC Insurance Comp:</td>
                  <td className="text-center text-sm text-orange-700">{license.WCInsuranceCompany !== "" ? license.WCInsuranceCompany : "NOT LONGER ASSOCIATE"}</td>
                </tr>
                <tr>
                  <td>WC Exp Date:</td>
                  <td className="text-center font-semibold">{license.WCExpirationDate}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bg-slate-600 text-orange-500 rounded-b-xl mt-1 shadow-lg shadow-slate-500">
            <h2 className="text-center text-xl font-bold text-orange-500">
              Personnel List:
            </h2>
            <table className="w-full">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                <tr> 
                  <td className="text-center">{personalList.Name}</td>
                  <td className="text-center">{personalList.title}</td>
                </tr>
              </tbody>
            </table>
          </div> 
        </div>
      )}
    </>
  )
};

export default ContractorsData;
