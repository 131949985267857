import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Layout from "../components/Layout";
import { AiOutlineSave } from "react-icons/ai";
import { useNavigate,useParams } from "react-router-dom";
import {
  MdContactEmergency,
  MdContactMail,
  MdContactPhone,
  MdAddCircle,
  MdChat,
  MdDoDisturbOn
} from "react-icons/md";
import Swal from "sweetalert2";

const ManageContractor = () => {
  const [contractor, setContractor] = useState([]);
  const navigate = useNavigate();
  const [notes, setNotes] = useState("");
  const [newNote, setNewNote] = useState("");
  const [isValid, setIsValid] = useState(true);
  const { _id } = useParams();
  
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      contactName: contractor.name,
      contactEmail: contractor.email,
      contactPhone: contractor.phone,
      status: contractor.State,
    },
  });

  const notify = () => toast('Note can not be empty!!!.', {
    style: {
      border: '1px solid #EE0000',
      background:'#ee0000',
      padding: '16px',
      color: 'white',
      fontWeight: 'bold',
    },
  });
  const notify2 = () => toast('Phone must be exactly 10 digits!!!.', {
    style: {
      border: '1px solid #EE0000',
      background:'#ee0000',
      padding: '16px',
      color: 'white',
      fontWeight: 'bold',
    },
  });
  const notify3 = () => toast('Enter a valid email!!!.', {
    style: {
      border: '1px solid #EE0000',
      background:'#ee0000',
      padding: '16px',
      color: 'white',
      fontWeight: 'bold',
    },
  });

  const onSubmit = async (data) => {
    if (!isValid) {
      Swal.fire('Error', 'Please check the fields', 'error');
      return;
    }
    try {
      const response = await axios.put(`https://cslb.nexeraadvisors.com/api/license/update/${_id}`, {
        name: data.contactName,
        email: data.contactEmail,
        phone: "+1" + data.contactPhone,
        notes: notes,
        State: data.status,
      });
  
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Contractor updated successfully",
          showConfirmButton: false,
          timer: 1500
        });
        /* navigate("/contractors"); */
      } else {
        Swal.fire('Error', 'Something went wrong', 'error');
      }
    } catch (error) {
      console.error("Error updating contractor:", error);
      Swal.fire('Error', 'Something went wrong', 'error');
    }
  };


  useEffect(() => {
    const fetchIndContractor = async () => {
      try {
        const response = await axios.get(`https://cslb.nexeraadvisors.com/api/license/${_id}`);
        setContractor(response.data[0]);
        setNotes(response.data[0].notes);
      } catch (error) {
        console.error("Error fetching contractor:", error);
      }
    };
    fetchIndContractor();
  }, [_id])

  useEffect(() => {
    if (contractor && contractor.phone) {
      setValue("contactName", contractor.name);
      setValue("contactEmail", contractor.email);
      setValue("contactPhone", contractor.phone.slice(2));
      setValue("status", contractor.State);
    }
  }, [contractor, setValue]);

  const addNote = () => {
    if (newNote.trim() === '') {
      notify();
      return;
    }
    setNotes([...notes, {text:newNote}]);
    setNewNote("");
  };

  const deleteNote = (noteIndex) => {
    setNotes(notes.filter((note, index) => index !== noteIndex));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'New':
        return 'bg-violet-200 font-semibold text-white px-2 rounded-xl';
      case 'Qualified':
        return 'bg-sky-200 font-semibold text-white px-2 rounded-xl';
      case 'Discussion':
        return 'bg-green-200 font-semibold text-white px-2 rounded-xl';
      case 'Negotiation':
        return 'bg-amber-200 font-semibold text-white px-2 rounded-xl';
      case 'Won':
        return 'bg-orange-200 font-semibold text-white px-2 rounded-xl';
      case 'Lost':
        return 'bg-rose-200 font-semibold text-white px-2 rounded-xl';
      default:
        return 'bg-gray-200';
    }
  };

  //console.log(contractor)
  return (
    <Layout>
      <h1 className="text-2xl text-orange-300 font-bold text-center">
        Manage Contractor
      </h1>
      <div className="flex flex-col md:flex-row gap-2">
        <div className="border md:w-[85vw] p-2 mt-4 shadow-xl rounded-xl">
          <h2 className="font-bold text-slate-500 text-center">
            {contractor.businessName} - LicNo:{contractor.LicenseNo} - <span className={getStatusColor(contractor.State)}>{contractor.State}</span> <small className="text-gray-400">status </small>
             / <span className="text-orange-700">{contractor.language ? contractor.language : "Not language"}</span>
          </h2>
          <div className="text-slate-400">
            <p><span className="font-bold">Entity:</span> {contractor.entity}</p>
            <p className="flex flex-col md:flex-row justify-between">
              <span>
                <span className="font-bold">Issue date:</span> <span>{new Date(contractor.issueDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
              </span>
              <span>
              <span className="font-bold">Expiration date:</span> <span>{new Date(contractor.expireDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}</span>
              </span>
            </p>
            <p><span className="font-bold"> Surety Company:</span> <span className="text-sm">{contractor.CBSuretyCompany ? contractor.CBSuretyCompany : "No data" }</span></p>
            <p className="flex flex-col md:flex-row justify-between">
              <span>
                <span className="font-bold">Classification:</span> {contractor.classification}
              </span>
              <span>
                <span className="font-bold">License status:</span> {contractor.licenseStatus}
              </span>
            </p>
            <p><span className="font-bold">Workers Compensation:</span> {contractor.workersCompensationStatus}</p>
            <div className="flex justify-between">
              <div className="grow">
                <span className="font-bold">Phone:</span>
                <a className="bg-yellow-100 no-underline p-0.5 opacity-80 text-blue-600 font-medium" href={`tel:${contractor.phone}`}>{contractor.phone}</a>
              </div>
              <button className="bg-blue-300 hover:bg-blue-400 hover:shadow-lg
                                      hover:shadow-blue-400 text-white text-xl font-bold 
                                      py-1 rounded-full flex-1 mx-1" >
                <Link 
                            to={`/comunications/${contractor._id}`} 
                          >
                          <MdChat className="mx-auto"/>
                </Link>
              </button>
            </div>
          </div>
          <div onSubmit={handleSubmit}>

            <div className="flex flex-col md:flex-row gap-4">
              <div className="my-1 grow">
                <div className="my-2 w-full relative rounded-2xl shadow-xl">
                  <Controller
                    name="contactName"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="w-full p-2 bg-primary border border-input rounded-2xl"
                        type="text"
                        placeholder="Contact Name"
                        required
                      />
                    )}
                  />
                  <MdContactEmergency
                    className="absolute right-2 top-3 text-2xl text-gray-400"
                  />
                </div>
              </div>
              <div className="my-1">
                <div className="my-2 w-full relative rounded-2xl shadow-xl">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <select 
                        {...field}
                        className="w-full p-2 bg-primary text-gray-600 border border-input rounded-2xl"
                      >
                        <option value="" disabled>Select - Status</option>
                        <option value="New" className='bg-violet-300 text-white font-semibold'>New</option>
                        <option value="Qualified" className='bg-sky-300 text-white font-semibold'>Qualified</option>
                        <option value="Discussion" className='bg-green-300 text-white font-semibold'>Discussion</option>
                        <option value="Negotiation" className='bg-amber-300 text-white font-semibold'>Negotiation</option>
                        <option value="Won" className='bg-orange-300 text-white font-semibold'>Won</option>
                        <option value="Lost" className='bg-rose-300 text-white font-semibold'>Lost</option>
                      </select>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4">
              <div className="my-1 grow">
                <div className="my-1 w-full relative rounded-2xl shadow-xl">
                  <Controller
                      name="contactPhone"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="w-full p-2 bg-primary border border-input rounded-2xl"
                          type="text"
                          placeholder="Contact Phone"
                          required
                          onChange={(e) => {
                            // Only allow digits
                            const value = e.target.value.replace(/\D/g, '');
                            // Update the field value
                            field.onChange(value);
                          }}
                          onBlur={(e) => {
                            // Check if the value is exactly 10 digits when the input loses focus
                            if (e.target.value.length !== 10) {
                              notify2();
                              setIsValid(false);
                            } else {
                              setIsValid(true);
                          }}
                        }
                        />
                      )}
                    />
                  <MdContactPhone
                    className="absolute right-2 top-3 text-2xl text-gray-400"
                  />
                </div>
              </div>
              <div className="my-1 grow">
                <div className="my-1 w-full relative rounded-2xl shadow-xl">
                  <Controller
                      name="contactEmail"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="w-full p-2 bg-primary border border-input rounded-2xl"
                          type="email"
                          placeholder="Contact Email"
                          required
                          onChange={(e) => {
                            // Update the field value
                            field.onChange(e.target.value);
                          }}
                          onBlur={(e) => {
                            // Regular expression for email validation
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            // Check if the value is a valid email when the input loses focus
                            if (!emailRegex.test(e.target.value)) {
                              notify3();
                              setIsValid(false);
                            } else {
                              setIsValid(true);
                          }}
                        }
                        />
                      )}
                    />
                  <MdContactMail
                    className="absolute right-2 top-3 text-2xl text-gray-400"
                  />
                </div>
              </div>
            </div>

{/*             <div className="my-4">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <select 
                    {...field}
                    className="w-full p-2 bg-primary text-gray-400 border border-input rounded-2xl"
                  >
                    <option value="" disabled>Select - Status</option>
                    <option value="new" className='bg-violet-300 text-white font-semibold'>New</option>
                    <option value="qualified" className='bg-sky-300 text-white font-semibold'>Qualified</option>
                    <option value="discussion" className='bg-green-300 text-white font-semibold'>Discussion</option>
                    <option value="negotiation" className='bg-amber-300 text-white font-semibold'>Negotiation</option>
                    <option value="won" className='bg-orange-300 text-white font-semibold'>Won</option>
                    <option value="lost" className='bg-rose-300 text-white font-semibold'>Lost</option>
                  </select>
                )}
              />
            </div> */}

            <div className="my-4">
              <div className="my-1 w-full relative rounded-2xl shadow-xl">
                <input
                  type="text"
                  className="w-full p-2 bg-primary border border-input rounded-2xl"
                  placeholder="Write your new note and click on the + button to add it -->"
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                />
                <button type="button" onClick={addNote}>
                  <MdAddCircle
                    className="absolute right-2 top-3 text-2xl text-orange-400"
                  />
                </button>
              </div>
                <h2 className="font-bold text-slate-500 text-center">
                  Tracing - Notes
                </h2>
                <ul className="bg-gray-100 px-2 pb-3">
                  {notes && notes.map((note, index) => 
                    <li key={index} className="w-[100%] py-1 flex justify-between border-b-2">
                      <div>
                        {index + 1}. {note.text} - 
                        <small className="text-orange-500">
                          {isNaN(new Date(note.date).getTime()) ? "Remember press save" : new Date(note.date).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}
                        </small>
                      </div>
                      <button onClick={() => deleteNote(index)}>
                        <MdDoDisturbOn className="text-red-500 text-xl" />
                      </button>
                    </li>
                  )}
                </ul>
            </div>

            <button
              className="flex justify-center bg-orange-300 text-white font-bold w-full 
                        my-2 p-2 bg-button text-btnText rounded-2xl shadow-xl hover:bg-orange-400"
              onClick={handleSubmit(onSubmit)}
            >
              Save <AiOutlineSave className="ml-2 mt-1.5 text-white text-lg" />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageContractor;
