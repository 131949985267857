import React, { useState, useRef } from "react";
import Logo from "../assets/img/logo1.png";
import { UserAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiFillLock, AiOutlineMail } from "react-icons/ai";
import { ImEnter } from "react-icons/im";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";

const ActivationAccount = () => {
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { signIn } = UserAuth();
  const emailRef = useRef();
  const { token } = useParams();

  const onSubmit = async (data) => {
    const { email, password } = data;
    try {
      console.log(email, password, token);
      const response = await axios.post(
        "https://cslb.nexeraadvisors.com/api/auth/newpass",
        {
          email: email,
          password: password,
          activationCode: token,
        }
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: `Welcome back`,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*   const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      navigate("/");
    } catch (e) {
      if (e.message === "Firebase: Error (auth/wrong-password).") {
        Swal.fire({
          icon: "error",
          title: "Wrong Password",
          showConfirmButton: false,
          timer: 1000,
        });
      } else if (e.message === "Firebase: Error (auth/user-not-found).") {
        Swal.fire({
          icon: "error",
          title: "User Not Found",
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `${e.message}`,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    }
  }; */

  return (
    <div className="flex justify-center content-between">
      <div className=" w:1/2 md:w-1/3 bg-slate-100 p-5 mt-40 rounded-lg">
        <div>
          <img src={Logo} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Please create your Password</h1>

          <div className="my-4">
            <label>Password</label>
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
              <input
                className="w-full p-2 bg-primary border border-input rounded-2xl"
                type="password"
                name="password"
                {...register("password")}
              />
              <AiFillLock className="absolute right-2 top-3 text-gray-400" />
            </div>
          </div>
          <button
            className="
                      flex
                      justify-center
                      bg-orange-400 
                      text-white
                      font-bold 
                      w-full 
                      my-2 
                      p-3 
                      bg-button 
                      text-btnText 
                      rounded-2xl 
                      shadow-xl 
                      hover:bg-orange-600"
          >
            Create
            <ImEnter className="ml-2 mt-1.5" />
          </button>
        </form>
        <p className="my-4 flex items-center justify-between">
          {/*           <Link to="/signup" className="text-accent  text-orange-400 hover:text-orange-700">
            Register?
          </Link> */}
        </p>
      </div>
    </div>
  );
};

export default ActivationAccount;
