const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  if (pageNumbers.length <= 1) {
    return null;
  }

  return (
    <nav className="fixed bottom-1 left-0 right-0 flex justify-center mb-1">
      {pageNumbers.map((number) => (
        <button
          key={number} 
          className="
                    page-link 
                    bg-orange-400 
                    font-bold 
                    text-slate-600 
                    mx-2 
                    py-1 
                    px-4 
                    rounded-lg 
                    hover:bg-orange-600 
                    hover:text-slate-300
                    hover:shadow-xl 
                    hover:shadow-orange-800"
          onClick={() => paginate(number)}
        >
          {number}
        </button>
      ))}
    </nav>
  );
};
export { Pagination };
