import React, { useState } from "react";
import { setInputValue } from "../features/input/inputSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import Loading from "../components/Loading";
import axios from "axios";

const CloseExpireList = () => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const [month, setMonth] = useState(
    (currentDate.getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(currentDate.getFullYear().toString());
  const [lastLicenses, setLastLicenses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (licenseNo) => {
    dispatch(setInputValue(licenseNo));
    navigate("/infocontractor");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://cslb.nexeraadvisors.com/api/licenseMaster/WCExpirationDate/${month}/${year}`
      );
      setLastLicenses(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const formatFecha = (fecha) => {
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getUTCDate().toString().padStart(2, "0");
    const mes = (fechaConvertida.getUTCMonth() + 1).toString().padStart(2, "0");
    const anio = fechaConvertida.getUTCFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = lastLicenses.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(lastLicenses.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64 2xl:px-80">
      <div className=" md:w-full rounded-xl">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <h2 className="text-center text-xl">
            Enter the{" "}
            <b>
              <i> month</i>
            </b>{" "}
            to find next expiring contractors.{" "}
          </h2>
          <div className="my-2 w-full relative rounded-2xl shadow-xl">
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="month"
              onChange={(e) => {
                const [year, month] = e.target.value.split('-');
                setMonth(month);
                setYear(year);
              }}
            />
          </div>
          <button
            type="submit"
            className="flex justify-center bg-orange-400 text-white font-bold 
                          w-full my-2 p-2 bg-button text-btnText rounded-2xl 
                          shadow-xl hover:bg-orange-600"
          >
            search{" "}
            <AiOutlineSearch className="ml-2 mt-1.5 text-white text-lg" />
          </button>
        </form>
        <div className="flex justify-end gap-1">
          {pageNumbers.map((number) => (
            <button
              key={number}
              onClick={() => setCurrentPage(number)}
              className="bg-orange-500 text-white px-2 rounded-md"
            >
              {number}
            </button>
          ))}
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="mt-5 bg-slate-100 p-6 rounded-xl">
            <div className="max-h-[310px] overflow-y-auto">
              <table className=" w-full">
                <thead>
                  <tr>
                    <th className="text-orange-500 ">License No</th>
                    <th className="text-orange-500">Business Name</th>
                    <th className="text-orange-500">Exp Date</th>
                    <th className="text-orange-500">See it</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((license) => (
                    <tr key={license._id}>
                      <td className="text-center align-middle">
                        <h1 color="red">{license.LicenseNo}</h1>
                      </td>
                      <td className="text-center align-middle">
                        {license.BusinessName}
                      </td>
                      <td className="text-center align-middle">
                        {formatFecha(license.WCExpirationDate)}
                      </td>
                      <td className="text-center align-middle">
                        <button
                          onClick={() => handleButtonClick(license.LicenseNo)}
                        >
                          <FaEye color="orange" />
                        </button>
{/*                         <Link to={`/newinfo/${license.LicenseNo}`} target="_blank" rel="noopener noreferrer">
                          <FaEye color="orange" className="ms-2"/>
                        </Link> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CloseExpireList;
