import React, { useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setInputValue } from "../features/input/inputSlice";
import {
  MdContactEmergency,
  MdContactMail,
  MdContactPhone,
} from "react-icons/md";
import { BiSave } from "react-icons/bi";
import { BsSkipForwardFill } from "react-icons/bs";

const ManageForm = ({
  setContactName,
  setContactEmail,
  setContactPhone,
  setNotes,
  setSendSms,
  sendSms,
  contactName,
  contactEmail,
  contactPhone,
  notes,
  status,
  user,
  /*  handleSendSms, */
  /*  setTextSms, */
  messageLanguage,
  setMessageLanguage,
  licenseData,
  companies,
}) => {
  const templates = {
    english: {
      New: `Congratulations on your contractor license! I'm ${user?.fullname} from ${user?.companyId.name}. We offer competitive insurance for contractors. Would you like to get a free, no-obligation quote?`,
      Qualified: `Hello ${contactName}, thank you for your interest in ${user?.companyId.name}. As I mentioned, we specialize in insurance for contractors. I'd love to help you find the best coverage for your needs. Would you like to schedule a brief call?`,
      Discussion: `Hello ${contactName}, thank you for your time. I have created a proposal with the coverage options we discussed. Please review it and send me your feedback or questions.`,
      Negotiation: `Hello ${contactName}, thank you for your feedback on the proposal. I have made some adjustments to tailor it to your needs and budget. I think we are close to an agreement. Would you like to review the new proposal together?`,
      Won: `Congratulations ${contactName} on joining ${user?.companyId.name}! Your contractor insurance policy is now active. If you have any questions or need assistance, feel free to contact us.`,
      Lost: `Thank you for considering ${user?.companyId.name}, ${contactName}. Although we didn't reach an agreement this time, we appreciate your interest. We invite you to stay in touch as we may have other options that fit your needs in the future.`,
    },
    spanish: {
      New: `¡Felicidades por tu licencia de contratista! Soy ${user?.fullname} de ${user?.companyId.name}. Ofrecemos seguros para contratistas a precios competitivos. ¿Te gustaría obtener una cotización gratuita y sin compromiso?`,
      Qualified: `Hola ${contactName}, gracias por tu interés en ${user?.companyId.name}. Como te mencioné, nos especializamos en seguros para contratistas. Me encantaría ayudarte a encontrar la mejor cobertura para tus necesidades. ¿Te gustaría programar una breve llamada?`,
      Discussion: `Hola ${contactName}, gracias por tu tiempo. He creado una propuesta con las opciones de cobertura que discutimos. Por favor, revísala y envíame tus comentarios o preguntas.`,
      Negotiation: `Hola ${contactName}, gracias por tus comentarios sobre la propuesta. He realizado algunos ajustes para adaptarla a tus necesidades y presupuesto. Creo que estamos cerca de llegar a un acuerdo. ¿Te gustaría revisar juntos la nueva propuesta?`,
      Won: `¡Felicidades ${contactName} por unirte a ${user?.companyId.name}! Tu póliza de seguro para contratistas ya está activa. Si tienes preguntas o necesitas asistencia, no dudes en contactarnos.`,
      Lost: `Gracias por considerar ${user?.companyId.name}, ${contactName}. Aunque no llegamos a un acuerdo en esta ocasión, apreciamos tu interés. Te invitamos a mantener el contacto, ya que podríamos tener otras opciones que se ajusten a tus necesidades en el futuro.`,
    },
  };
  const [emailValid, setEmailValid] = useState(false);
  const [statusS, setStatusS] = useState("New");
  const [textSms, setTextSms] = useState("");
  const dispatch = useDispatch();

  const handleLanguageChange = (language) => {
    setMessageLanguage(language);
    setTextSms(templates[language][status]);
  };

  const handleSendSms = (value, text) => {
    /*   setStatus(value); */
    setTextSms(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const savedContractor = {
      LicenseNo: licenseData.license.LicenseNo,
      name: contactName,
      email: contactEmail,
      phone: `+1${contactPhone}`,
      businessName: licenseData.license.BusinessName,
      entity: licenseData.license.BusinessType,
      issueDate: licenseData.license.IssueDate,
      expireDate: licenseData.license.ExpirationDate,
      licenseStatus: licenseData.license.PrimaryStatus,
      classification: licenseData.license.Classifications,
      bondNumber: licenseData.license.CBNumber,
      bondAmount: licenseData.license.CBAmount,
      bondEffectiveDate: licenseData.license.CBEffectiveDate,
      workersCompensationStatus: licenseData.license.WorkersCompCoverageType,
      CBSuretyCompany: licenseData.license.CBSuretyCompany,
      language: messageLanguage,
      notes: { text: notes || "Created" },
      State: statusS,
      companyId: companies._id,
      userId: user._id,
    };
    /* lastQuery(licenseNumber); */
    try {
      console.log({
        savedContractor,
        sendSms,
        user: user,
        text: textSms,
      });
      const response = await fetch(
        "https://cslb.nexeraadvisors.com/api/license/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            savedContractor,
            sendSms,
            user: user,
            text: textSms,
          }),
        }
      );
      //console.log(response)
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Contract Saved",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          dispatch(setInputValue(licenseData.license.LicenseNo + 1));
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "already been saved",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };
  //console.log(licenseData)

  return (
    <form
      onSubmit={handleSubmit}
      className="border md:w-[35vw] p-2 mt-1 shadow-xl rounded-2xl"
    >
      <h2 className="font-bold text-slate-500 text-xl mt-1">
        Advisor Management:
      </h2>
      <div className="my-2">
        <div className="my-1 w-full relative rounded-2xl shadow-xl">
          <input
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            className="w-full p-2 bg-primary border border-input rounded-2xl"
            type="text"
            placeholder="Contact Name"
            required
          />
          <MdContactEmergency className="absolute right-2 top-3 text-2xl text-gray-400" />
        </div>
      </div>
      <div className="my-2">
        <div className="my-1 w-full relative rounded-2xl shadow-xl">
          <input
            value={contactPhone}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 10 && /^[0-9]*$/.test(value)) {
                setContactPhone(value);
              }
            }}
            className="w-full p-2 bg-primary border border-input rounded-2xl"
            type="text"
            placeholder="Phone (Only 10 digits, NO: spaces,dashes,special chars)"
            required
          />
          <MdContactPhone className="absolute right-2 top-3 text-2xl text-gray-400" />
        </div>
      </div>
      <div className="my-2 w-full relative rounded-2xl shadow-xl py-2 bg-white">
        <label
          htmlFor="message-language-english"
          className="text-gray-400 ml-3"
        >
          Language
        </label>
        <input
          type="checkbox"
          id="message-language-english"
          name="message-language-english"
          checked={messageLanguage === "english"}
          onChange={() => handleLanguageChange("english")}
          className="ml-4 mr-2 rounded-2xl focus:ring-0 border-gray-300 cursor-pointer accent-orange-600 "
        />
        <label htmlFor="message-language-english" className="text-gray-400">
          English
        </label>
        <input
          type="checkbox"
          id="message-language-spanish"
          name="message-language-spanish"
          checked={messageLanguage === "spanish"}
          onChange={() => handleLanguageChange("spanish")}
          className="ml-4 mr-2 rounded-2xl focus:ring-0 border-gray-300 cursor-pointer accent-orange-600 "
        />
        <label htmlFor="message-language-spanish" className="text-gray-400">
          Spanish
        </label>
      </div>
      <div className="my-2">
        <div className="my-1 w-full relative rounded-2xl shadow-xl">
          <input
            value={contactEmail}
            onChange={(e) => {
              const value = e.target.value;
              setContactEmail(value);
              const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
              setEmailValid(emailPattern.test(value));
            }}
            className="w-full p-2 bg-primary border border-input rounded-2xl"
            type="email"
            placeholder="Email"
          />
          <MdContactMail className="absolute right-2 top-3 text-2xl text-gray-400" />
        </div>
      </div>
      <div className="my-2">
        <div>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder=" Notes:"
            name="notes:"
            id=""
            cols="60"
            rows="4"
            className="mt-2 p-2 border rounded-lg w-full shadow-xl"
          ></textarea>
        </div>
      </div>
      <div className="my-2">
        <div className="my-1 w-full relative rounded-2xl shadow-xl">
          <select
            value={statusS}
            onChange={(e) => setStatusS(e.target.value)}
            className="w-full p-2 bg-primary text-gray-400 border border-input rounded-2xl"
            required
          >
            <option
              value="New"
              className="bg-violet-300 text-white font-semibold"
            >
              New
            </option>
            <option
              value="Qualified"
              className="bg-sky-300 text-white font-semibold"
            >
              Qualified
            </option>
            <option
              value="Discussion"
              className="bg-green-300 text-white font-semibold"
            >
              Discussion
            </option>
            <option
              value="Negotiation"
              className="bg-amber-300 text-white font-semibold"
            >
              Negotiation
            </option>
            <option
              value="Won"
              className="bg-orange-300 text-white font-semibold"
            >
              Won
            </option>
            <option
              value="Lost"
              className="bg-rose-300 text-white font-semibold"
            >
              Lost
            </option>
          </select>
        </div>
      </div>
      <div className="mt-2 px-2">
        <div className="my-2 w-full relative rounded-2xl shadow-xl py-2">
          <input
            type="checkbox"
            id="send-sms"
            name="send-sms"
            disabled={status === 0 || status === "0"}
            checked={sendSms}
            onChange={() => setSendSms(!sendSms)}
            className="ml-4 mr-2 rounded-2xl focus:ring-0 border-gray-300 cursor-pointer accent-orange-600 "
          />
          {sendSms ? (
            <div className="mt-3 px-4">
              <label
                htmlFor="message-language-spanish"
                className="text-gray-400"
              >
                {templates[messageLanguage][status]}
              </label>
            </div>
          ) : (
            <label htmlFor="send-sms" className="text-gray-400 ">
              Send SMS
            </label>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className=" flex bg-slate-500 py-1 px-4 border border-orange-500 rounded-lg text-orange-400 font-bold"
        >
          Save <BiSave className="text-xl mt-1" />
          <small>
            <BsSkipForwardFill className="mt-1.5 ml-1" />
          </small>
        </button>
      </div>
    </form>
  );
};

export default ManageForm;
