import React from 'react'
import CountyStat from './CountyStat'

const Carousel = ({countyLicenses}) => {
  //console.log(countyLicenses)
  return (
    <div className='my-2 w-[100%] ml-4 mr-4'>
    <div className='overflow-hidden w-full'>
        <div className='flex whitespace-nowrap animate-scroll'>
          {countyLicenses.map((item, index) => (
          <CountyStat key={index} county={item.county} value={item.count} />
          ))}
          {countyLicenses.map((item, index) => (
              <CountyStat key={index + countyLicenses.length} county={item.county} value={item.count} />
          ))}
{/*         {countyLicenses.map((county) => {
          return (
            <CountyStat key={county.county} county={county.county} value={county.count}/>
          )
        })} */}
        </div>
    </div>
</div>
  )
}

export default Carousel