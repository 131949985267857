import React,{useState} from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setInputValue } from "../features/input/inputSlice";
import { useNavigate } from "react-router";
import { GiMiningHelmet } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { FaEye } from "react-icons/fa"
import axios from "axios";


const SearchByName = () => {
    const [contractors, setContractors] = useState(null);
    const { register, handleSubmit, reset } = useForm();
    const postBusinessName = async (businessName) => {
        try {
          const response = await axios.post('https://cslb.nexeraadvisors.com/api/licenseMaster/businessName/', {
            businessName: businessName
          });
          setContractors(response.data);
          return response.data;
        } catch (error) {
          console.error('Error posting business name:', error);
          throw error;
        }
    };
    const navigate = useNavigate();
    const [inputValue, setInputValueLocal] = useState("");
    const dispatch = useDispatch();
    
    const onSubmit =async (data) => {
        const response =await postBusinessName(data.businessName);
    };

    const handleButtonClick = (licenseNo) => {
        setInputValueLocal(licenseNo);
        dispatch(setInputValue(licenseNo));
        navigate("/infocontractor");
    };

  return (
    <div className="px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64 2xl:px-80">
      <div className=" md:w-full rounded-xl">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-center text-xl">
            Enter the contractor <b><i>business name</i></b> or <b><i>name keyword</i></b> to find matching contractors.{" "}
          </h2>
          <div className="my-2 w-full relative rounded-2xl shadow-xl">
            <input
                type="text"
                {...register("businessName", { required: true })}
                placeholder="Search..."
                className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
            />
            <GiMiningHelmet className="absolute right-2 top-3 text-xl text-gray-500" />
          </div>
          <button
            type="submit"
            className="flex justify-center bg-orange-400 text-white font-bold 
                        w-full my-2 p-2 bg-button text-btnText rounded-2xl 
                        shadow-xl hover:bg-orange-600"
          >
            search <AiOutlineSearch className="ml-2 mt-1.5 text-white text-lg" />
          </button>
        </form>
      </div>
      <table className="mt-4  shadow-xl rounded-lg w-full">
        <thead>
          <tr>
            <th style={{textAlign: "left"}} className="p-1">Contractor</th>
            <th style={{textAlign: "left"}}>License</th>
            <th style={{textAlign: "center"}}>See it</th>
          </tr>
        </thead>
        {contractors ? (
        <tbody>
        {contractors.map((contractor) => (
            <tr key={contractor._id} className="border border-b-2 border-gray-200">
                <td className="p-1">{contractor.BusinessName}</td>
                <td>{contractor.LicenseNo}</td>
                <td className="text-center">
                    <button 
                        className="text-orange-600 hover:text-orange-400"
                        onClick={() => {handleButtonClick(contractor.LicenseNo)}}
                    >
                        <FaEye />
                    </button>
                </td>
            </tr>
        ))}
        </tbody>
        ) : null}
      </table>
    </div>
  );
};
export default SearchByName;