export async function lastQuery(licenseNumber) {
    const last = { lastLicense: licenseNumber };
    try {
      await fetch(
        "https://cslb.nexeraadvisors.com/api/lastQuery/64359ad844fb6acc41a0d1ba",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(last),
        }
      );
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
/*     console.log(licenseNumber,"desde lastQuery")
    console.log("ok") */
}