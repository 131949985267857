import React, { useEffect, useState } from "react";
import { UserAuth } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../common/Alert";
import axios from "axios"; //1
import { useForm } from "react-hook-form"; //3

const Register = () => {
  const [companies, setCompanies] = useState([]); //2
  const navigate = useNavigate();
  const { createUser } = UserAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); //4

  const onSubmit = async (data) => {
    const { name, email, phone, password, confirmPassword, company } = data;
    try {
      const response = await axios.post(
        "https://cslb.nexeraadvisors.com/api/users/create",
        {
          fullname: name,
          email: email,
          phone: phone,
          companyId: company,
        }
      );
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: `We sent you an email to ${email}`,
          showConfirmButton: false,
          timer: 2500,
        });
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      // handle error
    }
  };

  /*   const onSubmit = (data) => {
    const { name, email, phone, password, confirmPassword, company } = data;
    if (password === confirmPassword) {
      createUser(email, password)
        .then((result) => {
          axios.post("https://cslb.nexeraadvisors.com/api/users/create", {
            fullname: name,
            email: email,
            phone: phone,
            companyId: company
          })
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "User Created",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              navigate("/");
            });
          })
          .catch((err) => {
            Alert("error", "Failed to create user on the server");
          });
        })
        .catch((err) => {
          Alert("error", "Email already in use");
        });
    } else {
      Alert("error", "Passwords do not match...");
    }
  }; */

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await axios.get(
        "https://cslb.nexeraadvisors.com/api/companies"
      );
      const data = await response.data;
      setCompanies(data);
    };
    fetchCompanies();
  }, []);

  console.log(companies);

  return (
    <div className="ml-[25vw] mt-4">
      <div className="bg-slate-100 p-6 w-[50vw] rounded-xl">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <h1 className="text-center text-xl font-bold">Register a user</h1>
            <Link
              to="/"
              className="text-accent  text-orange-400 hover:text-orange-700 text-center"
            >
              Back to Main
            </Link>
          </div>
          <div className="my-4">
            <label>Name</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="text"
              name="name"
              {...register("name")}
            />
          </div>
          <div className="my-4">
            <label>Email</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="email"
              name="email"
              {...register("email")}
            />
          </div>
          <div className="my-4">
            <label>Phone</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="tel"
              name="phone"
              {...register("phone")}
            />
          </div>
          <div className="my-4">
            <label>Company</label>
            <select
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="tel"
              name="phone"
              {...register("company")}
            >
              <option value="">Select a company</option>
              {companies.map((company) => (
                <option key={company._id} value={company._id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          {/*      <div className="my-4">
            <label>Password</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="password"
              name="password"
              {...register("password")}
            />
          </div>
          <div className="my-4">
            <label>Confirm Password</label>
            <input
              className="w-full p-2 bg-slate-200 border border-input rounded-2xl"
              type="password"
              name="confirmPassword"
              {...register("confirmPassword")}
            />
          </div> */}
          <button
            className="
              bg-orange-400 
              text-white
              font-bold 
              w-full 
              my-2 
              p-2 
              bg-button 
              text-btnText 
              rounded-2xl 
              shadow-xl 
              hover:bg-orange-600"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
