// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvFfRdVMhGZmYV5eO6vNf6r0xz92I0Vao",
  authDomain: "cslb-799e2.firebaseapp.com",
  projectId: "cslb-799e2",
  storageBucket: "cslb-799e2.appspot.com",
  messagingSenderId: "660327570930",
  appId: "1:660327570930:web:a5df2052894c0ecd945f6b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

