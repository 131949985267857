import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { MdSend } from "react-icons/md";
import { FaPhone,FaEdit } from "react-icons/fa";
import { setInputValue } from "../features/input/inputSlice";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMessagesStore } from "../store/UnreadMessages";

const Socket = ({contractor}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyId = contractor.companyId._id;
  const [trigger, setTrigger] = useState(false);
  const [smsReceive, setSmsReceive] = useState([]);
  const [smsSent, setSmsSent] = useState([]);
  const [chats, setChats] = useState([]);
  const { register, handleSubmit, reset } = useForm();

  const { unreadMessagesA } = useMessagesStore();
  //console.log("from socket:",unreadMessagesA)
  const messagesEndRef = useRef(null);

  const queryClient = useQueryClient();

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '+1 ' + match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }

  const handleButtonClick = (id) => {
    dispatch(setInputValue(id));
    console.log(`/contractor-detail/${id}`)
    navigate(`/contractor-detail/${id}`);
  };

  const onSubmit = async (data) => {
    const smsToSend = {
      userId: contractor.userId._id,
      fromNumber: contractor.companyId.phone,
      toNumber: [contractor.phone],
      message: data.inputMessage,
      companyName: contractor.companyId.name,
      contact: {
        email: contractor.companyId.email,
        phone: contractor.companyId.phone,
        web: contractor.companyId.web,
      },
    };
    try {
    //console.log(smsToSend)
      const res = await axios.post(
        "https://cslb.nexeraadvisors.com/api/comunications/send-sms",
        smsToSend
      );
      reset();
      queryClient.invalidateQueries(["getChats", companyId]);
      setTrigger(!trigger);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const fetchSms = async () => {
      try {
        const sentRes = await axios.get(`https://cslb.nexeraadvisors.com/api/sms/out/user/${contractor.userId._id}`);
        const receiveRes = await axios.get(`https://cslb.nexeraadvisors.com/api/sms/in/${contractor.companyId._id}`);
        setSmsSent(sentRes.data);
        setSmsReceive(receiveRes.data);
        setChats([...receiveRes.data]);
      } catch (error) {
        console.error("Error fetching SMS:", error);
      }
    };
    fetchSms();
  }, [contractor, unreadMessagesA, trigger]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chats]);

  console.log(contractor)

    return (
      <div className="flex flex-col h-[80vh] px-5">
      <div className="flex justify-between py-1 px-6 bg-gray-400 rounded-md mt-1">
        <span className="text-white">
          <span className="font-semibold">{contractor.name}</span> - <small>{contractor.businessName}</small>
        </span>
        <div>
          <div className="flex gap-1 justify-end">
            <button 
              title="Edit" 
              className="border border-orange-300 py-1 px-2 rounded-md hover:bg-orange-300"
              onClick={() => handleButtonClick(contractor._id)} 
            >
              <FaEdit className="inline-block ml-2 text-orange-200" size={15}/>
            </button>
            <a 
              title="Phone call" 
              className="border border-orange-300 py-1 px-2 rounded-md hover:bg-orange-300" 
              href={`tel:${contractor.phone}`}
            >
              <FaPhone className="inline-block ml-2 text-orange-200" size={15}/>
            </a>
{/*             <div className="text-lg text-orange-200 py-1 px-2 border border-orange-300 rounded-md">{formatPhoneNumber(contractor.phone)}</div>
            <p className="text-lg text-orange-200 py-1 px-2 border border-orange-300 rounded-md">{formatPhoneNumber(contractor.phone)}</p> */}
          </div>
        </div>
      </div>
      <div className="flex-1 h-9/12 overflow-y-auto px-4 py-1">
        {chats &&
          [...chats]
          .filter(chat => (chat.direction === "in" && chat.from === `${contractor.phone}` ) || (chat.direction === "out" && chat.to === `${contractor.phone}`))
          .map((chat) => {
            const date = new Date(chat.time);
            const formattedDate = `${
              date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()} - ${date.getHours()}:${
              date.getMinutes() < 10 ? "0" : ""
            }${date.getMinutes()}`;
            return (
              <div
                key={chat._id}
                className={`px-3.5 py-1 my-4 ${
                  chat.direction === "out"
                    ? "bg-gray-100 border border-gray-400 ml-72 rounded-tl-xl rounded-bl-xl rounded-br-xl shadow-xl"
                    : "bg-orange-100 border border-orange-300 self-end rounded-tr-xl rounded-br-xl rounded-bl-xl mr-72 shadow-xl"
                }`}
              >
                <p className="flex justify-between">
                  <small className="text-xs text-gray-700">
                    {formattedDate}
                  </small>
                </p>
                <p>
                  {chat.direction === "out"
                    ? chat.text.split("\n")[1]
                    : chat.text}
                </p>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex items-center justify-between px-4 py-2 border-t border-gray-300"
      >
        <input
          type="text"
          {...register("inputMessage", { required: true })}
          placeholder="Write your message..."
          className="flex-1 px-3 py-2 mr-2 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500"
        />
        <button
          type="submit"
          className="px-4 py-2 text-white bg-orange-500 rounded-lg hover:bg-orange-600 focus:outline-none focus:bg-orange-600"
        >
          Send <MdSend className="inline-block ml-2" />
        </button>
      </form>
    </div>
  );
};
export default Socket;
