import React, { useState } from "react";
import { setInputValue } from "../features/input/inputSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetLastLicenses } from "../lib/react-query/queries";
import { BiRightArrow } from "react-icons/bi";
import  Loading  from "../components/Loading";

const ListLicense = () => {
  const dispatch = useDispatch();
  const {data: lastLicenses, isLoading} = useGetLastLicenses();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const handleButtonClick = (licenseNo) => {
    dispatch(setInputValue(licenseNo));
    navigate("/infocontractor");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatFecha = (fecha) => {
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getUTCDate().toString().padStart(2, "0");
    const mes = (fechaConvertida.getUTCMonth() + 1).toString().padStart(2, "0");
    const anio = fechaConvertida.getUTCFullYear();
    return `${mes}/${dia}/${anio}`;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = lastLicenses ? lastLicenses.slice(indexOfFirstItem, indexOfLastItem) : [];

  if (isLoading) {
    return <Loading />;
  }

  //console.log(lastLicenses)

  return (
    <div className="table-container bg-slate-50  md:mx-40 px-10 mt-5 py-5  rounded-2xl">
      <div className="">
        <h2 className="text-xl font-bold text-orange-500"> Recent Licenses:</h2>
        <div className="flex justify-end gap-1">
          {[...Array(5)].map((_, i) => (
            <button key={i} onClick={() => handlePageChange(i + 1)} className="bg-orange-500 text-white px-2 rounded-sm">
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-5 bg-slate-100 p-6 rounded-xl">
        <div
          className= "max-h-[310px] overflow-y-auto" 
        >
          <table className=" w-full">
            <thead>
              <tr>
                <th className="text-orange-500 ">License No</th>
                <th className="text-orange-500">Issue Date</th>
                <th className="text-orange-500">Business Name</th>
                <th className="text-orange-500">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((license) => (
                <tr key={license._id}>
                  <td className="text-center align-middle">
                    <h1 color="red">{license.LicenseNo}</h1>
                  </td>
                  <td className="text-center align-middle">
                    {formatFecha(license.IssueDate)}
                  </td>
                  <td className="text-center align-middle">
                    {license.BusinessName}
                  </td>
                  <td className="text-center align-middle">
                    <button
                      onClick={() =>
                        handleButtonClick(license.LicenseNo)
                      }
                    >
                      <BiRightArrow color="green" />
                    </button>
{/*                     <Link to={`/newinfo/${license.LicenseNo}`} target="_blank" rel="noopener noreferrer">
                      <BiRightArrow color="green" />
                    </Link> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListLicense;
