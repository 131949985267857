import { create } from 'zustand'
import { persist } from "zustand/middleware";

export const useUserStore = create(
    persist(
        (set) =>({
            userA:[],
            addUserA: (user) => 
                set((state) => ({
                    userA: [...state.userA, user]
                })),
            removeUserA: () => 
                set((state) => ({
                    userA: []
                })),
        }),
        { name:"userAuth"}
    )
)