import React from 'react'

const CountyStat = ({county,value}) => {
  return (
    <div className='mx-1 w-[10vw] flex-shrink-0 shadow-2xl'>
        <div className='border border-orange-200 bg-orange-50 rounded-lg'>
            <h2 className='text-md text-center font-bold text-gray-500'>{county}</h2>
            <h3 className='text-lg text-center font-bold text-orange-600'>{value}</h3>
        </div>
    </div>
  )
}

export default CountyStat