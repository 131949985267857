import { useForm } from 'react-hook-form'
import { MdOutlineMail } from 'react-icons/md'
import { UserAuth } from '../contexts/AuthContext'
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const EmailChannel = ({contractor}) => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const  { user }  = UserAuth();
    const navigate = useNavigate();

    const onSubmit = data => {
        const emailToSend = {
            toEmail: contractor.email,
            message: data.message,
            companyName: contractor.companyId.name,
            contact: {
                email: contractor.companyId.email,
                phone: contractor.companyId.phone,
                web: contractor.companyId.web,
            }
        }
        /* console.log(emailToSend) */
        axios.post('https://cslb.nexeraadvisors.com/api/comunications/send-email', emailToSend)
          .then(response => {
            Swal.fire('Success', 'Email sent successfully', 'success');
            navigate('/');
          })
          .catch(error => {
            Swal.fire('Error', 'An error occurred while sending the email', 'error');
          });
      }

  return (
    <div className='w-full h-full'>
        <form className='flex flex-col items-stretch' onSubmit={handleSubmit(onSubmit)}>
            <div className='flex mt-1'>
                <label htmlFor="name" className='text-gray-700 w-1/5'>Name:</label>
                <div className='text-black border bg-gray-200 ms-2 grow w-4/5 px-1 rounded-md focus:border-2 focus:border-orange-300 focus:outline-none'>
                    {contractor.name}
                </div>
            </div>
            <div className='flex mt-1'>
                <label htmlFor="email" className='text-gray-700 w-1/5'>Email:</label>
                <div className='text-black border bg-gray-200 ms-2 grow w-4/5 px-1 rounded-md focus:border-2 focus:border-orange-300 focus:outline-none'>
                    {contractor.email}
                </div>
            </div>
            <div className='flex mt-1'>
                <label htmlFor="message" className='text-gray-700 w-1/5'>Message: </label>
                <textarea id="message"  {...register('message')} className='text-black border bg-gray-200 ms-2 grow w-4/5 rounded-md focus:border-2 focus:border-orange-300 focus:outline-none'></textarea>
            </div>
            <button type="submit" className='bg-orange-400 flex justify-center gap-1 items-center mt-2 p-2 font-bold text-white rounded-md hover:bg-orange-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2'>
                SEND <MdOutlineMail size={20}/> <small>e-mail</small>
            </button>
        </form>
    </div>
  )
}

export default EmailChannel