/* import "./styles.css"; */
import React from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "January",
    new: 400,
    won: 240,
    lost: 160
  },
  {
    name: "February",
    new: 300,
    won: 139,
    lost: 221
  },
  {
    name: "March",
    new: 200,
    won: 98,
    lost: 102
  },
  {
    name: "April",
    new: 278,
    won: 270,
    lost: 8
  },
  {
    name: "May",
    new: 189,
    won: 100,
    lost: 89
  },
  {
    name: "June",
    new: 239,
    won: 180,
    lost: 59
  },
  {
    name: "July",
    new: 349,
    won: 180,
    lost: 169
  },
    {
        name: "August",
        new: 352,
        won: 180,
        lost: 169
    },
    {
        name: "September",
        new: 320,
        won: 150,
        lost: 170
    },
    {
        name: "October",
        new: 300,
        won: 180,
        lost: 169
    },
    {
        name: "November",
        new: 370,
        won: 195,
        lost: 175
    },
    {
        name: "December",
        new: 400,
        won: 280,
        lost: 120
    }
];

export default function AreaGraph() {
    return (
        <ResponsiveContainer width="100%" height="90%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="won"
            stroke="#FDBA74"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="new" stroke="#C4B5FD" />
          <Line type="monotone" dataKey="lost" stroke="#FDA4AF" />
        </LineChart>
        </ResponsiveContainer>
      );
}