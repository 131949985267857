import React,{useState,useEffect} from 'react'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setInputValue } from "../features/input/inputSlice";
import { useForm } from 'react-hook-form';
import Layout from '../components/Layout';
import { UserAuth } from "../contexts/AuthContext";
import { useUserStore } from '../store/UserAuthenticated'
import Swal from "sweetalert2";
import { MdContactEmergency, MdContactMail, MdContactPhone, MdApartment, MdSave } from "react-icons/md";

const TransactContractor = () => {
  const { userA } = useUserStore();
  const [dbUser, setDbUser] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, formState:{errors} } = useForm();
  const dispatch = useDispatch();
  const inputValue = useSelector(setInputValue);
  const licenseNumber = Number(inputValue.payload.input.inputValue);
  const  { user }  = UserAuth();

  const onSubmit = async data => {
    //console.log(data)
    const uniqueNumber = "000" + Date.now().toString();
    data.LicenseNo = uniqueNumber;
    const savedContractor = {
      LicenseNo: data.LicenseNo,
      name: data.name,
      email: data.email,
      phone: data.phone,
      businessName: data.businessName,
      entity: "",
      issueDate: "",
      expireDate: "",
      licenseStatus: "",
      classification: "",
      bondNumber: "",
      bondAmount: "",
      bondEffectiveDate: "",
      workersCompensationStatus: "",
      CBSuretyCompany: "",
      notes: {text: data.notes || "Created"},
      State: data.status,
      companyId: companies._id,
      userId: dbUser[0]._id,
      transcribed: true,
    };
    //console.log(savedContractor);
    try {

      const response = await fetch(
        "https://cslb.nexeraadvisors.com/api/license/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            savedContractor,
            user: dbUser[0],
          }),
        }
      );
      //console.log(response)
      if (response.ok) {
        
        Swal.fire({
          icon: "success",
          title: "Contract Saved",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          dispatch(setInputValue(licenseNumber + 1));
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "already been saved",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  useEffect(() => {
    const fetchUserDb = async () => {
      const response = await fetch(
        `https://cslb.nexeraadvisors.com/api/users/by-email/${userA[0].email}`
      );
      const data = await response.json();
      setDbUser(data);
      const fetchCompany = async () => {
        console.log(data)
        //console.log(`https://cslb.nexeraadvisors.com/api/companies/${data[0].companyId}`)
        const response = await fetch(
          `https://cslb.nexeraadvisors.com/api/companies/${data[0].companyId._id}`
        );
        const companyData = await response.json();
        setCompanies(companyData);
      };
      fetchCompany();
    };
    fetchUserDb();
  }, []);

  return (
    <Layout>
      <div className="border md:w-[85vw] p-2 mt-1 shadow-xl rounded-2xl">
        <h2 className="font-bold text-slate-500 text-xl mt-2">New Transact License:</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-2">
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
              <input
                {...register('name',{required: true})}
                className="w-full p-2 bg-primary border border-input rounded-2xl"
                type="text"
                placeholder="Contact Name"
              />
                <MdContactEmergency
                className="absolute right-2 top-3 text-2xl text-gray-400"
              />
            </div>
          </div>
          <div className="my-2">
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
              <input
                {...register('businessName',{required: true})}
                className="w-full p-2 bg-primary border border-input rounded-2xl"
                type="text"
                placeholder="Bussines Name"
              />
                <MdApartment
                className="absolute right-2 top-3 text-2xl text-gray-400"
              />
            </div>
          </div>
          <div className="my-2">
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
                  <input
                    {...register('phone',{required: true, maxLength: 10, minLength: 10, pattern: /^[0-9]*$/})}
                    className="w-full p-2 bg-primary border border-input rounded-2xl"
                    type="text"
                    placeholder="Phone (Only 10 digits, NO: 'Spaces,Dashes or Special chars')"
                    aria-invalid={errors.phone ? "true" : "false"}
                  />
                    <MdContactPhone
                    className="absolute right-2 top-3 text-2xl text-gray-400"
                  />
                </div>
                {errors.phone && <p className="text-red-500">This field is required and must be have 10 digits</p>}
          </div>
          <div className="my-2">
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
            <input
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address"
                }
              })}
              className="w-full p-2 bg-primary border border-input rounded-2xl"
              type="email"
              placeholder="Email"
              aria-invalid={errors.email ? "true" : "false"}
            />
                <MdContactMail
                className="absolute right-2 top-3 text-2xl text-gray-400"
              />
            </div>
            {errors.email && <p className="text-red-500">This field is required and must be a valid email</p>}
          </div>
          <div className="my-2">
            <div>
              <textarea
                {...register('notes')}
                placeholder=" Notes:"
                cols="60"
                rows="3"
                className="mt-2 p-2 border rounded-lg w-full shadow-xl"
              ></textarea>
            </div>
          </div>
          <div className="my-2">
            <div className="my-2 w-full relative rounded-2xl shadow-xl">
                <select 
                  className="w-full p-2 bg-primary text-gray-400 border border-input rounded-2xl"
                  {...register('status',{required: true})}
                  defaultValue={"New"}
                >
                  <option value="New" className='bg-violet-300 text-white font-semibold'>New</option>
                  <option value="Qualified" className='bg-sky-300 text-white font-semibold'>Qualified</option>
                  <option value="Discussion" className='bg-green-300 text-white font-semibold'>Discussion</option>
                  <option value="Negotiation" className='bg-amber-300 text-white font-semibold'>Negotiation</option>
                  <option value="Won" className='bg-orange-300 text-white font-semibold'>Won</option>
                  <option value="Lost" className='bg-rose-300 text-white font-semibold'>Lost</option>
                </select>
            </div>
          </div>
          <button 
            type="submit"
            className="flex justify-center bg-orange-400 text-white font-bold 
            w-full my-4 p-2 bg-button text-btnText rounded-2xl 
            shadow-xl hover:bg-orange-600"
          
          >
            save <MdSave className="ml-2 mt-1.5 text-white text-lg" />
          </button>
        </form>
      </div>
  </Layout>
  )
}

export default TransactContractor

