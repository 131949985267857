import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { AiOutlineDelete } from "react-icons/ai";
import { MdDelete,MdEdit,MdChat } from "react-icons/md";
import { Pagination } from "../components/Pagination";
import Layout from "../components/Layout";
import Loading from "../common/Loading";
import { useGetUsers, useGetContractors } from "../lib/react-query/queries";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import axios from "axios";
import { UserAuth } from "../contexts/AuthContext";
import { useUserStore } from '../store/UserAuthenticated'

const TransactList = () => {
  const { userA,removeUserA } = useUserStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(400);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = UserAuth();

  const queryClient = useQueryClient();

  const { data: users, isLoading:usersLoading } = useGetUsers();
  const filteredUser = users?.find(userf => userf.email === userA[0].email);
  const { data: contractorsq, isLoading: contractorsqLoading, refetch: refetchContractors } = useGetContractors(filteredUser?.companyId?._id);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  //console.log(contractorsq)

  const filteredContractors = searchTerm
  ? contractorsq
    ? contractorsq.filter((contractor) => {
        return (
          contractor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contractor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contractor.businessName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contractor.State.toLowerCase().includes(searchTerm.toLowerCase()) // added this line
        );
      })
    : []
  : contractorsq || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const current = filteredContractors.filter(contractor => contractor.userId?.email === userA[0].email)
  const currentItems = current.slice(indexOfFirstItem, indexOfLastItem);

  console.log(current)

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff7f50',
      cancelButtonColor: 'gray',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`https://cslb.nexeraadvisors.com/api/license/delete/${id}`)
        .then(async () => {
          queryClient.invalidateQueries('contractors');
        })
        .catch((error) => {
          console.error("Error deleting the contractor:", error);
        });
      }
    })
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'New':
        return 'bg-violet-300 font-semibold text-white px-2 rounded-xl';
      case 'Qualified':
        return 'bg-sky-300 font-semibold text-white px-2 rounded-xl';
      case 'Discussion':
        return 'bg-green-300 font-semibold text-white px-2 rounded-xl';
      case 'Negotiation':
        return 'bg-amber-300 font-semibold text-white px-2 rounded-xl';
      case 'Won':
        return 'bg-orange-300 font-semibold text-white px-2 rounded-xl';
      case 'Lost':
        return 'bg-rose-300 font-semibold text-white px-2 rounded-xl';
      default:
        return 'bg-gray-400';
    }
  };


  if ( usersLoading || contractorsqLoading) {
    return <Loading />;
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

   return (
    <Layout>
      <div className="ml-[5vw] mt-6">
        <div className="bg-slate-100 p-6 w-[80vw] rounded-xl shadow-xl">
          <h1 className="text-2xl text-orange-400 font-bold text-center">Saved Contractors(transact)</h1>
          <div className="my-4 ">
            <div className="my-2 w-full relative rounded-2xl shadow-xl mb-10">
              <input
                className="w-full p-2 bg-slate-200 border border-input rounded-2xl text-slate-500 pl-10"
                type="text"
                placeholder="Search by: status, business name, contact name or email"
                value={searchTerm}
                onChange={handleSearch}
              />
              <AiOutlineDelete
                className="absolute right-2 top-3 text-xl text-gray-500 cursor-pointer"
                onClick={() => setSearchTerm("")}
              />
            </div>
          </div>
          <div className="overflow-x-auto h-[60vh] overflow-y-auto  ">
            <table className="table-auto w-full ">
              <thead>
                <tr>
                  <th className="px-4 pb-10 text-slate-600" >
                    Status 
                  </th>
                  <th className="px-4 pb-10 text-slate-600">Business Name</th>
                  <th className="px-4 pb-10 text-slate-600">Contact</th>
                  <th className="px-4 pb-10 text-slate-600">Email</th>
                  <th className="px-4 pb-10 text-slate-600">Owner</th>
                  <th className="px-4 pb-10 text-slate-600">Actions</th>
                </tr>
              </thead>

                <tbody>
                  {currentItems && currentItems.map((contractor) => {
                    if (contractor.transcribed === true) {
                      return (
                        <tr key={contractor._id}>
                        <td className="border-b-2 text-center px-4 py-2">
                        <span className={getStatusColor(contractor.State)}>{contractor.State}</span>
                        </td>
                        <td className="border-b-2 text-center px-4 py-2">
                          {contractor.businessName}
                        </td>
                        <td className="border-b-2 text-center px-4 py-2">
                          {contractor.name}
                        </td>
                        <td className="border-b-2 text-center px-4 py-2">
                          {contractor.email}
                        </td>
                        <td className="border-b-2 text-center px-4 py-2">
                          {contractor.userId ? contractor.userId.fullname : 'N/A'}
                        </td>
                        <td className="border-b-2 text-center px-4 py-2">
                          <div className="flex justify-center">
                            <button
                              className="bg-orange-600 hover:bg-orange-400 hover:shadow-lg
                                       hover:shadow-orange-600 text-white text-xl font-bold 
                                        py-1 rounded-full flex-1 mx-2"
                              onClick={() => handleDelete(contractor._id)}
                            >
                              <MdDelete className="mx-auto"/>
                            </button>
                            <Link 
                              to={`/contractor-detail/${contractor._id}`} 
                              className="bg-gray-500 hover:bg-gray-400 hover:shadow-lg
                                        hover:shadow-gray-500 text-white text-xl font-bold 
                                          py-1 rounded-full flex-1 mx-2"
                            >
                              <MdEdit className="mx-auto"/>
                          </Link>
                          <Link 
                            to={`/comunications/${contractor._id}`} 
                            className="bg-blue-500 hover:bg-blue-400 hover:shadow-lg
                                      hover:shadow-blue-500 text-white text-xl font-bold 
                                      py-1 rounded-full flex-1 mx-2"
                          >
                            <MdChat className="mx-auto"/>
                          </Link>
                          </div>
                        </td>
                      </tr>
                      )
                    }
                  })}
                </tbody>
            </table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={current.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TransactList;
